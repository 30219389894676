<template>
  <div>
    <draggable
      class="drag-form-table__content"
      :list="tableComponent.children"
      :group="{ name: 'form' }"
      ghost-class="ghost"
      :animation="200"
      handle=".drag-form-table__item"
      @add="handleWidgetTableAdd($event, tableComponent)"
      @end="$emit('change')"
    >
      <template v-if="tableComponent.children.length > 0">
        <div
          class="drag-form-table__item"
          v-for="(column, index) in tableComponent.children"
          :key="index"
          @click.stop="handleSelectTableWidget(index)"
        >
          <!--按钮-->
          <div
            v-if="column.type == 'btn'"
            class="drag-form-table drag-form-btn"
            style="float:none;"
            :class="{ active: selectWidget.prop == column.prop }"
          >
            <el-button
              :size="column.size"
              :plain="column.plain"
              :round="column.round"
              :circle="column.circle"
              :icon="column.myIcon"
              :type="column.myType"
              >{{ $t(column.label) }}</el-button
            >
            <el-button
              :title="$t('复制')"
              @click.stop="handleWidgetTableClone(column)"
              class="drag-action-clone"
              v-if="selectWidget.prop == column.prop"
              circle
              plain
              size="mini"
              type="primary"
            >
              <i class="el-icon-copy-document"></i>
            </el-button>
            <el-button
              :title="$t('删除')"
              @click.stop="handleWidgetTableDelete(index)"
              class="drag-action-delete"
              v-if="selectWidget.prop == column.prop"
              circle
              plain
              size="mini"
              type="danger"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </div>

          <el-form-item
            v-else
            :label="$t(column.label)"
            :prop="column.prop"
            :required="column.required"
            :class="{
              active: selectWidget.prop == column.prop,
              required: column.required,
            }"
            :show-message="false"
          >
            <drag-form-item
              
              :data="column"
            ></drag-form-item>

            <el-button
              :title="$t('复制')"
              @click.stop="handleWidgetTableClone(column)"
              class="drag-action-clone"
              v-if="selectWidget.prop == column.prop"
              circle
              plain
              size="mini"
              type="primary"
            >
              <i class="el-icon-copy-document"></i>
            </el-button>
            <el-button
              :title="$t('删除')"
              @click.stop="handleWidgetTableDelete(index)"
              class="drag-action-delete"
              v-if="selectWidget.prop == column.prop"
              circle
              plain
              size="mini"
              type="danger"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </el-form-item>
        </div>
      </template>
    </draggable>

    <el-button
      :title="$t('复制')"
      @click.stop="handleWidgetCloneTable(index)"
      class="drag-table-action-clone"
      v-if="selectWidget.prop == tableComponent.prop"
      circle
      plain
      size="mini"
      type="primary"
    >
      <i class="el-icon-copy-document" />
    </el-button>
    <el-button
      :title="$t('删除')"
      @click.stop="handleWidgetDeleteTable(index)"
      class="drag-table-action-delete"
      v-if="selectWidget.prop == tableComponent.prop"
      circle
      plain
      size="mini"
      type="danger"
    >
      <i class="el-icon-delete" />
    </el-button>
  </div>
</template>
<script>
import dragFormItem from "./dragFormItem";
export default {
  name: "drag-form-table",
  props: ["tableComponent", "select", "index", "plist"],
  components: { dragFormItem },
  inject: ["formDesign"],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    selectWidget: {
      get() {
        return this.select;
      },
      set(val) {
        this.$emit("update:select", val);
      },
    },
    formComponents() {
      return this.plist || [];
    },
  },
  methods: {
    handleSelectWidget(index) {
      this.selectWidget = this.formComponents[index];
    },
    handleWidgetDeleteTable(index) {
      if (this.formComponents.length - 1 === index) {
        if (index === 0) this.selectWidget = {};
        else this.handleSelectWidget(index - 1);
      } else this.handleSelectWidget(index + 1);

      this.$nextTick(() => {
        this.formComponents.splice(index, 1);
        this.$emit("change");
      });
    },
    handleWidgetCloneTable(index) {
      let cloneData = this.$deepClone(this.tableComponent);
      cloneData.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
      cloneData.children.forEach((t) => {
        t.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
        t.table = "";
        t.field = "";
      });
      cloneData.table = "";
      this.formComponents.splice(index + 1, 0, cloneData);
      this.$nextTick(() => {
        this.handleSelectWidget(index + 1);
        this.$emit("change");
      });
    },
    handleFormClickAdd(item) {
      this.tableComponent.children.push(item);
      this.handleWidgetTableAdd(
        { newIndex: this.tableComponent.children.length - 1 },
        this.tableComponent,
        true
      );
    },
    handleSelectTableWidget(index) {
      this.selectWidget = this.tableComponent.children[index];
    },
    handleWidgetTableAdd(evt, tableComponent, isNotSelect) {
      let newIndex = evt.newIndex;
      if (newIndex == 1 && newIndex > tableComponent.children.length - 1)
        newIndex = 0;
      const data = this.$deepClone(tableComponent.children[newIndex]);
      if (
        [
          "gridtable",
          "divider",
          "lable",
          "texteditor",
          //"upload",
          //"uploadimg",
          "viewtable",
          "card",
          //"btn",
          "collapse",
          "tab",
        ].includes(data.type)
      ) {
        tableComponent.children.splice(newIndex, 1);
        return;
      }

      if (!data.prop)
        data.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);

      if (!data.subfield && data.type == "layerselect") {
        data.columns.forEach((col) => {
          col.valueKey = "";
        });
      }

      data.subfield = true;
      switch (data.type) {
          case "time":
            data.labelWidth = 110;
            break;
          case "timerange":
            data.labelWidth = 200;
            break;
          case "datetime":
            data.labelWidth = 130;
            break;
          case "datetimerange":
            data.labelWidth = 200;
            break;
          case "upload":
            data.labelWidth = 113;
            break;
          case "uploadimg":
            data.labelWidth = 113;
            break;
          case "btn":
            data.labelWidth = 90;
            break;
          case "rate":
            data.labelWidth = 121;
            break;
          case "switch":
            data.labelWidth = 60;
            break;
          case "slider":
            data.labelWidth = 100;
            break;
          case "color":
            data.labelWidth = 135;
            break;
          case "icon":
            data.labelWidth = 150;
            break;
      }
      if (tableComponent.table) {
        data.table = tableComponent.table;
      } else {
        data.table = "";
      }

      delete data.icon;
      this.$set(tableComponent.children, newIndex, { ...data });
      if (!isNotSelect) {
        this.selectWidget = tableComponent.children[newIndex];
      }

      this.$emit("change");
    },
    handleWidgetTableClone(item) {
      const data = this.$deepClone(item);
      data.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
      data.table = "";
      data.field = "";
      this.$set(
        this.tableComponent.children,
        this.tableComponent.children.length,
        { ...data }
      );
      this.$nextTick(() => {
        this.selectWidget =
          this.tableComponent.children[this.tableComponent.children.length - 1];
        this.$emit("change");
      });
    },
    handleWidgetTableDelete(index) {
      if (this.tableComponent.children.length - 1 == index) {
        if (index == 0) this.selectWidget = this.tableComponent;
        else this.selectWidget = this.tableComponent.children[index - 1];
      } else this.selectWidget = this.tableComponent.children[index + 1];
      this.$nextTick(() => {
        this.tableComponent.children.splice(index, 1);
        this.$emit("change");
      });
    },
  },
};
</script>
