<template>
  <div>
    <el-form-item :label="$t('是否可见')">
      <el-switch v-model="data.display"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-company",
  props: ['data'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

