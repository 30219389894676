<template>
  <el-card :class="data.myclass" :header="data.label" :shadow="data.shadow">
    <l-form-wraper :myComponents="data.children" />
  </el-card>
</template>

<script>
export default {
  name: "card-viewer",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {}
  },
  computed: {
  },
  methods: {
  },
};
</script>