<template>
  <div>
    <el-form-item :label="$t('默认值')">
        <l-layer-select  
                v-model="data.default"
                :placeholder="$t('请选择')" 
                size="mini"
                :options="myOptions"
                :multiple="data.multiple"
                :columns="data.columns"
                :isPage="data.isPage"
            >
        </l-layer-select>
    </el-form-item>
    <el-divider>{{$t('私有属性')}}</el-divider>
    <!--<el-form-item label="是否多选">
        <el-switch v-model="data.multiple"></el-switch>
    </el-form-item>-->
    <el-form-item :label="$t('是否分页')">
        <el-switch v-model="data.isPage"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否多选')">
        <el-switch v-model="data.multiple"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('弹窗高')">
        <el-input-number v-model="data.height"
                       controls-position="right"
                       :placeholder="$t('请输入')"></el-input-number>
    </el-form-item>
    <el-form-item :label="$t('弹窗宽')">
        <el-input-number v-model="data.width"
                       controls-position="right"
                       :placeholder="$t('请输入')"></el-input-number>
    </el-form-item>
    <el-divider>{{$t('数据')}}</el-divider>
    <div style="text-align: center;margin-bottom:16px;" >
        <el-radio-group v-model="data.dataType" size="mini" @change="handleDataTypeChange" >
            <el-radio-button label="2">{{$t('数据字典')}}</el-radio-button>
            <el-radio-button label="3">{{$t('数据源')}}</el-radio-button>
        </el-radio-group>
    </div>
    <!--数据字典-->
    <div v-if="data.dataType == 2" >
        <el-form-item :label="$t('数据字典')">
            <l-tree-select
            v-model="data.dataCode"
            :options="dataItemClassifysTree"
            :placeholder="$t('请选择')"
            size="mini"
            :parent="false"

            @change="handleDataItemChange"
            >
            </l-tree-select>
        </el-form-item>
        <el-form-item :label="$t('值')">
            <l-select  
                    v-model="data.dataValueKey"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="[{value:'f_ItemValue',label:$t('值')},{value:'f_ItemName',label:$t('名')}]" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item :label="$t('标签')">
            <l-select  
                    v-model="data.dataLabelKey"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="[{value:'f_ItemValue',label:$t('值')},{value:'f_ItemName',label:$t('名')}]" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
    </div>
    <!--远端数据-->
    <div v-else >
        <el-form-item :label="$t('数据源')">
            <l-select  
                    v-model="data.dataCode"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="dataSource" 
                    labelKey="f_Name" 
                    valueKey="f_Code"

                    @change="handleDataSourceChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item :label="$t('值')">
            <l-select  
                    v-model="data.dataValueKey"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="myColNameList" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item :label="$t('标签')">
            <l-select  
                    v-model="data.dataLabelKey"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="myColNameList" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>

        <el-form-item :label="$t('上一级')">
            <l-select
                v-model="data.upCtrl"
                :placeholder="$t('请选择')"
                size="mini"
                :options="componentOptions"
            >
            </l-select>
        </el-form-item>
    </div>
    <el-divider>{{$t('数据列')}}</el-divider>
        <div class="reg-item" :key="index" v-for="(item,index) in data.columns" >
        <el-form-item :label="$t('列名')" style="margin-bottom:8px;">
            <el-input v-model="item.label"  
                    :placeholder="$t('请输入')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('绑定字段')" style="margin-bottom:8px;" >
            <l-select v-model="item.prop"  :options="data.dataType == 2?[{value:'f_ItemValue',label:$t('值')},{value:'f_ItemName',label:$t('名')}]:myColNameList" ></l-select>
        </el-form-item>
        <el-form-item :label="$t('赋值字段')" style="margin-bottom:8px;" >
            <l-select v-model="item.valueKey" :options="valueOptions"
                    :placeholder="$t('请输入')"></l-select>
        </el-form-item>
        <el-form-item :label="$t('宽度')" style="margin-bottom:8px;" >
            <el-input v-model="item.width"
                    :placeholder="$t('请输入')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('对齐')" style="margin-bottom:0;" >
            <l-select v-model="item.align" :options="alignOptions" ></l-select>
        </el-form-item>
        <el-form-item :label="$t('隐藏')" style="margin-bottom:0;" >
            <el-switch v-model="item.hidden"></el-switch>
        </el-form-item>

        <el-button :title="$t('删除')"
                    @click="handleRemoveCol(index)"
                    class="reg-item-delete"
                    circle
                    plain
                    size="mini"
                    type="danger">
            <i class="el-icon-close"></i>
        </el-button>
    </div>
    <div class="mt-8" >
        <el-button class="ml-8" type="primary" size="mini" @click="handleAddCol"  >{{$t('添加列')}}</el-button>
    </div>
    
    <el-divider></el-divider>
    <el-form-item :label="$t('是否只读')">
        <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否必填')">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
import mixin from '../../../mixin'
export default {
    name: "config-layerselect",
    mixins: [mixin()],
    inject: ["formDesign"],
    props: ['data'],
    data () {
        return {
            alignOptions:[{value:'left',label:'靠左'},{value:'center',label:'居中'},{value:'right',label:'靠右'}]
        }
    },
    created(){
        this.lr_loadDataItemClassifys && this.lr_loadDataItemClassifys()
        this.lr_loadDataSourceList && this.lr_loadDataSourceList()
        this.loadDataSourceCol()
    },
    computed:{
        dataItemClassifysTree(){
            if(this.lr_dataItemClassifysTree){
                return this.lr_dataItemClassifysTree
            }
            else{
                return []
            }
        },
        dataSource(){
            if(this.lr_dataSource){
                return this.lr_dataSource
            }
            else{
                return []
            }
        },
        valueOptions(){
            const res = []
            const formInfo = this.formDesign.toSaveData()
            if(!this.data.subfield){
                formInfo.tabList.forEach(tab=>{
                    tab.components.forEach(com=>{
                        if(!['divider','gridtable','card','btn'].includes(com.type) && com.prop != this.data.prop ){
                            res.push({value:com.prop,label:com.label})
                        }
                    })
                })
            }
            else{
                formInfo.tabList.forEach(tab=>{
                    tab.components.forEach(com=>{
                        if(['gridtable'].includes(com.type) && com.children.findIndex(t=>t.prop == this.data.prop) != -1 ){
                             com.children.forEach(c=>{
                                 if(c.prop != this.data.prop ){
                                     res.push({value:c.prop,label:c.label})
                                 }
                             })
                        }
                    })
                })
            }
            return res
        }
    },
    methods: {
        handleDataTypeChange(){
            this.data.dataCode = '';
            this.data.dataValueKey = '';
            this.data.dataLabelKey = '';


            this.data.default = '';
        },
        handleAddDatas(){
            this.data.options.push({label:'选项' + (this.data.options.length + 1) ,value:this.data.options.length + 1});
        },
        handleRemoveDatas(index){
            if(this.data.default == this.data.options[index].value){
                this.data.default = '';
            }
            this.data.options.splice(index,1);
        },

        handleDataItemChange(){
            this.data.default = ''
            if(!this.$validatenull(this.data.dataCode) && this.lr_loadDataItem){
                this.lr_loadDataItem(this.data.dataCode)
            }
        },
        handleDataSourceChange(){
            this.data.default = ''
            this.data.dataValueKey = ''
            this.data.dataLabelKey = ''
            if(!this.$validatenull(this.data.dataCode) && this.lr_loadDataSourceColNames){
                this.lr_loadDataSourceColNames(this.data.dataCode)
                this.lr_loadDataSourceData(this.data.dataCode)
            }
        },
        handleDataSourceKeyLabelChange(){
            this.data.default = ''
        },


        handleAddCol(){
            this.data.columns.push({
                label:`第${this.data.columns.length}列`,
                prop:'',
                valueKey:'',
                width:100,
                align:'left',
                hidden:false
            })
        },
        handleRemoveCol(index){
            this.data.columns.splice(index,1)
        }
    }
}
</script>

