<template>
  <el-row>
    <draggable
      :class="['drag-form-list']"
      :list="componentsShowList"
      :group="{ name: 'form' }"
      ghost-class="ghost"
      :animation="300"
      @add="handleFormAdd"
    >
      <template v-for="(column, index) in componentsShowList">
        <!--编辑子表-->
        <div
          class="drag-form-table"
          v-if="column.type == 'gridtable'"
          :key="column.prop"
          :class="{ active: selectWidget.prop == column.prop }"
          @click.stop="handleSelectWidget(index)"
        >
          <div class="drag-form-table__title">{{ $t(column.label) }}</div>
          <drag-form-table
            :tableComponent="column"
            :index="index"
            :select.sync="selectWidget"
            :plist="componentsShowList"
            :ref="column.prop"
            @change="$emit('change')"
          ></drag-form-table>
        </div>
        <!--视图表格-->
        <div
          class="drag-form-table viewtable"
          v-else-if="column.type == 'viewtable'"
          :key="column.prop"
          :class="{ active: selectWidget.prop == column.prop }"
          @click.stop="handleSelectWidget(index)"
        >
          <div class="drag-form-table__title">{{ $t(column.label) }}</div>
          <l-table :columns="column.columns"></l-table>
          <el-button
            :title="$t('复制')"
            @click.stop="handleWidgetClone(index)"
            class="drag-action-clone"
            v-if="selectWidget.prop == column.prop"
            circle
            plain
            size="mini"
            type="primary"
          >
            <i class="el-icon-copy-document"></i>
          </el-button>
          <el-button
            :title="$t('删除')"
            @click.stop="handleWidgetDelete(index)"
            class="drag-action-delete"
            v-if="selectWidget.prop == column.prop"
            circle
            plain
            size="mini"
            type="danger"
          >
            <i class="el-icon-delete"></i>
          </el-button>
        </div>

        <el-col
          v-else
          :key="column.prop"
          :md="column.span || 12"
          :xs="24"
          :offset="column.offset || 0"
        >
          <!--卡片-->
          <div
            class="drag-form-table"
            v-if="column.type == 'card'"
            :key="column.prop"
            :class="{ active: selectWidget.prop == column.prop }"
            @click.stop="handleSelectWidget(index)"
          >
            <div class="drag-form-table__title">{{ $t(column.label) }}</div>
            <drag-form-card
              :myComponent="column"
              :index="index"
              :plist="componentsShowList"
              :select.sync="selectWidget"
              :ref="column.prop"
              @change="$emit('change')"
            >
            </drag-form-card>
          </div>

          <!--折叠面板-->
          <div
            class="drag-form-table"
            v-else-if="column.type == 'collapse'"
            :key="column.prop"
            :class="{ active: selectWidget.prop == column.prop }"
            @click.stop="handleSelectWidget(index)"
          >
            <div class="drag-form-table__title">{{ $t(column.label) }}</div>
            <drag-form-collapse
              :myComponent="column"
              :index="index"
              :plist="componentsShowList"
              :select.sync="selectWidget"
              :ref="column.prop"
              @change="$emit('change')"
            >
            </drag-form-collapse>
          </div>

          <!--选项卡-->
          <div
            class="drag-form-table"
            v-else-if="column.type == 'tab'"
            :key="column.prop"
            :class="{ active: selectWidget.prop == column.prop }"
            @click.stop="handleSelectWidget(index)"
          >
            <div class="drag-form-table__title">{{ $t(column.label) }}</div>
            <drag-form-tab
              :myComponent="column"
              :index="index"
              :plist="componentsShowList"
              :select.sync="selectWidget"
              :ref="column.prop"
              @change="$emit('change')"
            >
            </drag-form-tab>
          </div>

          <!--按钮-->
          <div
            class="drag-form-table drag-form-btn"
            v-else-if="column.type == 'btn'"
            :key="column.prop"
            :class="{ active: selectWidget.prop == column.prop }"
            @click.stop="handleSelectWidget(index)"
          >
            <el-button
              :size="column.size"
              :plain="column.plain"
              :round="column.round"
              :circle="column.circle"
              :icon="column.myIcon"
              :type="column.myType"
              >{{ $t(column.label) }}</el-button
            >
            <el-button
              :title="$t('复制')"
              @click.stop="handleWidgetClone(index)"
              class="drag-action-clone"
              v-if="selectWidget.prop == column.prop"
              circle
              plain
              size="mini"
              type="primary"
            >
              <i class="el-icon-copy-document"></i>
            </el-button>
            <el-button
              :title="$t('删除')"
              @click.stop="handleWidgetDelete(index)"
              class="drag-action-delete"
              v-if="selectWidget.prop == column.prop"
              circle
              plain
              size="mini"
              type="danger"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </div>

          <!-- lable组件 -->
          <div
            class="drag-form-table drag-form-btn"
            v-else-if="column.type == 'lable'"
            :key="column.prop"
            :class="{ active: selectWidget.prop == column.prop }"
            @click.stop="handleSelectWidget(index)"
          >
          <div :style="{'text-align':column.contentPosition, 'font-size':`${column.fontSize}px`, color:column.color, 'font-weight':'bold'}">
            {{ $t(column.label) }}
          </div>
            
            <el-button
              :title="$t('复制')"
              @click.stop="handleWidgetClone(index)"
              class="drag-action-clone"
              v-if="selectWidget.prop == column.prop"
              circle
              plain
              size="mini"
              type="primary"
            >
              <i class="el-icon-copy-document"></i>
            </el-button>
            <el-button
              :title="$t('删除')"
              @click.stop="handleWidgetDelete(index)"
              class="drag-action-delete"
              v-if="selectWidget.prop == column.prop"
              circle
              plain
              size="mini"
              type="danger"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </div>
          <!-- lable -->

          <!--表单常规组件-->
          <div v-else @click.stop="handleSelectWidget(index)">
            <el-form-item
              class="widget-form-item"
              :label="column.type != 'divider' ? $t(column.label) : ''"
              :labelWidth="
                column.labelWidth == undefined
                  ? undefined
                  : column.labelWidth + 'px'
              "
              :prop="column.prop"
              :required="column.required"
              :class="{
                active: selectWidget.prop == column.prop,
                required: column.required,
              }"
              :show-message="false"
            >
              <drag-form-item :data="column"></drag-form-item>
              <el-button
                :title="$t('复制')"
                @click.stop="handleWidgetClone(index)"
                class="drag-action-clone"
                v-if="selectWidget.prop == column.prop"
                circle
                plain
                size="mini"
                type="primary"
              >
                <i class="el-icon-copy-document"></i>
              </el-button>
              <el-button
                :title="$t('删除')"
                @click.stop="handleWidgetDelete(index)"
                class="drag-action-delete"
                v-if="selectWidget.prop == column.prop"
                circle
                plain
                size="mini"
                type="danger"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </el-form-item>
          </div>
        </el-col>
      </template>
    </draggable>
  </el-row>
</template>

<script>
import DragFormTable from "./dragFormTable";
import DragFormItem from "./dragFormItem";
import DragFormCard from "./dragFormCard.vue";
import DragFormTab from "./dragFormTab.vue";
import DragFormCollapse from "./dragFormCollapse.vue";
export default {
  name: "drag-form-wraper",
  components: {
    DragFormTable,
    DragFormCard,
    DragFormTab,
    DragFormCollapse,
    DragFormItem,
  },
  inject: ["formDesign"],
  props: {
    select: Object,
    componentsList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectWidget: {
      get() {
        return this.select;
      },
      set(val) {
        this.$emit("update:select", val);
      },
    },
    componentsShowList() {
      return this.componentsList;
    },
    dbTables() {
      return this.formDesign.dbTables;
    },
  },
  data() {
    return {
      form: {},
      notInputComponents: [
        "divider",
        "lable",
        "viewtable",
        "card",
        "tab",
        "collapse",
        "btn",
      ],
    };
  },
  methods: {
    handleGetVueComponent(refs, prop) {
      let vueComponent = refs[prop];
      if (!vueComponent) {
        for (const id in refs) {
          vueComponent = this.handleGetVueComponent(refs[id][0].$refs, prop);
          if (vueComponent) {
            break;
          }
        }
      }
      return vueComponent;
    },

    handleSelectWidget(index) {
      this.selectWidget = this.componentsShowList[index];
    },
    handleWidgetDelete(index) {
      if (this.componentsShowList.length - 1 === index) {
        if (index === 0) this.selectWidget = {};
        else this.handleSelectWidget(index - 1);
      } else this.handleSelectWidget(index + 1);
      this.$nextTick(() => {
        this.componentsShowList.splice(index, 1);
        this.$emit("change");
      });
    },
    handleWidgetClone(index) {
      let cloneData = this.$deepClone(this.componentsShowList[index]);
      if (cloneData.type == "guid") {
        cloneData.default = "";
      }
      cloneData.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
      //cloneData.table = "";
      cloneData.field = "";
      this.componentsShowList.splice(index + 1, 0, cloneData);
      this.$nextTick(() => {
        this.handleSelectWidget(index + 1);
        this.$emit("change");
      });
    },
    handleFormClickAdd(item) {
      // 如果当前选中的是卡片或者子表直接向卡片或子表中添加
      if (
        ["card", "gridtable", "tab", "collapse"].includes(
          this.selectWidget.type
        ) &&
        !(["gridtable"].includes(this.selectWidget.type) && item.type == "card")
      ) {
        const res = this.handleGetVueComponent(
          this.$refs,
          this.selectWidget.prop
        )[0].handleFormClickAdd(item);
        if (res == false) {
          this.componentsShowList.push(item);
          this.handleFormAdd({ newIndex: this.componentsShowList.length - 1 });
        }
      } else {
        this.componentsShowList.push(item);
        this.handleFormAdd({ newIndex: this.componentsShowList.length - 1 });
      }
    },
    handleFormAdd(evt, isNotSelect) {
      const newIndex = evt.newIndex;
      const data = this.$deepClone(this.componentsShowList[newIndex]);
      if (data.type == "guid") {
        data.default = "";
      }
      if (!data.prop)
        data.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
      if (data.subfield) {
        data.table = "";
        data.field = "";
      }
      if (data.type == "gridtable") {
        // 如果是子表
        const tables = this.dbTables.filter((t) => t.type == "chlid");
        if (tables.length == 1) {
          data.table = tables[0].name;
        }
      } else if (!this.notInputComponents.includes(data.type)) {
        // 如果只有一个表的时候,默认赋值
        if (this.dbTables.length == 1) {
          data.table = this.dbTables[0].name;
        }
      }

      if (data.subfield && data.type == "layerselect") {
        data.columns.forEach((col) => {
          col.valueKey = "";
        });
      }

      delete data.icon;
      delete data.subfield;

      this.$set(this.componentsShowList, newIndex, data);
      if (!isNotSelect) {
        this.handleSelectWidget(newIndex);
      }

      this.$emit("change");
    },
  },
};
</script>
