export default [
    {
        title: '布局组件',
        list: [
            {
                type: 'gridtable',
                label: '设计子表',
                icon: 'learun-icon-array-table',
                span: 24,
                isAddBtn: true,
                isRemoveBtn: true,
                isShowNum: true,
                dataSource: [],
                children: [],
                changeDataEvent: '',
                classType:'1',
                isDESC: false,
                isRowFixed:false,
                isRowMerge:false,
                rowNum:1,
            }
            , {
                type: 'card',
                label: '卡片布局',
                icon: 'learun-icon-card-layout',
                span: 24,
                children: [],
                shadow: ''
            }
            , {
                type: 'collapse',
                label: '折叠面板',
                icon: 'learun-icon-collapse',
                span: 24,
                active:'0',
                tabList: [
                    {
                        text: '折叠面板一',
                        children: [],
                    }
                ],
                shadow: ''
            }
            , {
                type: 'tab',
                label: '选项卡',
                icon: 'learun-icon-tab',
                span: 24,
                active:'0',
                tabList: [
                    {
                        text: '选项卡一',
                        children: [],
                    }
                ],
                shadow: ''
            }
            , {
                type: 'divider',
                label: '分割线',
                html: '分割线',
                contentPosition: 'center',
                icon: 'learun-icon-parting-line',
                span: 24,
                labelWidth: 0,
                display: true
            }
            , {
                type: 'lable',
                label: '标题',
                contentPosition: 'center',
                icon: 'learun-icon-typeface',
                span: 24,
                fontSize: 30,
                color: '#000000',
                labelWidth: 0,
                display: true
            }
        ]
    },
    {
        title: '输入组件',
        list: [
            {
                type: 'input',
                label: '单行文本',
                icon: 'learun-icon-input-box',
                placeholder: '请输入',
                span: 24,
                patterns: [],
                display: true,
                default: ''
            }
            , {
                type: 'textarea',
                label: '多行文本',
                icon: 'learun-icon-input-multi',
                placeholder: '请输入',
                span: 24,
                display: true,
                default: ''
            }
            , {
                type: 'texteditor',
                label: '编辑器',
                icon: 'learun-icon-editor',
                placeholder: '请输入',
                span: 24,
                display: true,
                default: ''
            }
            , {
                type: 'number',
                label: '计数器',
                icon: 'learun-icon-digital-input',
                placeholder: '请输入',
                controlsPosition: 'default',
                controls: true,
                span: 24,
                display: true,
                default: '',
                disabled: false
            }
            , {
                type: 'password',
                label: '密码',
                icon: 'learun-icon-password-input',
                placeholder: '请输入',
                span: 24,
                display: true,
                default: ''
            }
        ]
    },
    {
        title: '选择组件',
        list: [
            {
                type: 'radio',
                label: '单选框组',
                icon: 'learun-icon-radio',
                span: 24,
                display: true,
                default: '1',
                dataType: '1', // 1 静态数据 2 数据字典 3 远端数据
                dataCode: '',
                dataValueKey: '',
                dataLabelKey: '',
                upCtrl: '',

                options: [{
                    value: '1',
                    label: '选项1'
                }, {
                    value: '2',
                    label: '选项2'
                },
                {
                    value: '3',
                    label: '选项3'
                }],
            }
            , {
                type: 'checkbox',
                label: '多选框组',
                icon: 'learun-icon-checkbox',
                span: 24,
                display: true,
                default: '',
                dataType: '1', // 1 静态数据 2 数据字典 3 远端数据
                dataCode: '',
                dataValueKey: '',
                dataLabelKey: '',
                upCtrl: '',
                options: [{
                    value: '1',
                    label: '选项1'
                }, {
                    value: '2',
                    label: '选项2'
                },
                {
                    value: '3',
                    label: '选项3'
                }]
            }
            , {
                type: 'select',
                label: '下拉选择',
                icon: 'learun-icon-select',
                span: 24,
                display: true,
                placeholder: '请选择',

                default: '',
                dataType: '1', // 1 静态数据 2 数据字典 3 远端数据
                dataCode: '',
                dataValueKey: '',
                dataLabelKey: '',
                upCtrl: '',
                upShowAll: false, // 上一级未选择加载全部开关
                options: [{
                    value: '1',
                    label: '选项1'
                }, {
                    value: '2',
                    label: '选项2'
                },
                {
                    value: '3',
                    label: '选项3'
                }]
            }
            , {
                type: 'selectMultiple',
                label: '下拉多选',
                icon: 'learun-icon-select-multi',
                span: 24,
                display: true,
                multiple: true,
                placeholder: '请选择',

                default: '',
                dataType: '1', // 1 静态数据 2 数据字典 3 远端数据
                dataCode: '',
                dataValueKey: '',
                dataLabelKey: '',
                upCtrl: '',
                collapseTags: false,
                options: [{
                    value: '1',
                    label: '选项1'
                }, {
                    value: '2',
                    label: '选项2'
                },
                {
                    value: '3',
                    label: '选项3'
                }]
            }
            , {
                key: 1,
                type: 'treeselect',
                label: '树形选择',
                icon: 'learun-icon-tree-select',
                span: 24,
                display: true,
                placeholder: '请选择',

                default: '',
                dataType: '1', // 1 静态数据 2 数据字典 3 远端数据
                dataCode: '',
                dataValueKey: '',
                dataLabelKey: '',
                dataIdKey: '',
                dataPIdKey: '',
                upCtrl: '',
                options: [
                    {
                        label: '选项一',
                        value: '0',
                        children: [{
                            label: '选项1-1',
                            value: '11',
                        }, {
                            label: '选项1-2',
                            value: '12',
                        }]
                    },
                    { label: '选项二', value: '1' },
                    { label: '选项三', value: '2' },
                ]
            }
            , {
                type: 'layerselect',
                label: '弹窗选择',
                icon: 'learun-icon-popup-window',
                span: 24,
                placeholder: '请选择',
                display: true,

                multiple: false,
                isPage: true,
                columns: [],
                height: 504,
                width: 960,


                default: '',
                dataType: '2', // 2 数据字典 3 远端数据
                dataCode: '',
                dataValueKey: '',
                dataLabelKey: '',
                upCtrl: '',
            }
            /*,{
                key:1,
                type: 'cascader',
                label: '级联选择',
                icon: 'fa fa-share-alt',
                span: 24,
                display: true,
                placeholder:'请选择',

                default:[],
                dataType:'1', // 1 静态数据 2 数据字典 3 远端数据
                dataCode:'',
                dataValueKey:'',
                dataLabelKey:'',
                options: [
                    {
                        label: '选项一',
                        value: '0',
                        children: [{
                            label: '选项1-1',
                            value: '11',
                        }, {
                            label: '选项1-2',
                            value: '12',
                        }]
                    },
                    { label: '选项二', value: '1' },
                    { label: '选项三', value: '2' },
                ]
            }*/

            , {
                type: 'time',
                label: '时间选择',
                icon: 'learun-icon-time',
                span: 24,
                display: true,
                default: '',
                placeholder: '选择时间',
                format: 'HH:mm:ss',
                readonly: false,
                clearable: true,
                selectableRange: ''
            }
            , {
                type: 'timerange',
                label: '时间范围',
                icon: 'learun-icon-time-range',
                span: 24,
                display: true,

                startPlaceholder: '开始时间',
                endPlaceholder: '结束时间',
                format: 'HH:mm:ss',
                default: '',
                readonly: false,
                clearable: true,
                isRange: true
            }
            , {
                type: 'datetime',
                label: '日期选择',
                icon: 'learun-icon-date',
                span: 24,
                display: true,
                dateType: 'date',
                format: 'yyyy-MM-dd',
                readonly: false,
                clearable: true,
                placeholder: '选择日期',
                default: ''
            }
            , {
                type: 'datetimerange',
                label: '日期范围',
                icon: 'learun-icon-date-range',
                span: 24,
                display: true,
                isRange: true,
                dateType: 'daterange',

                startPlaceholder: '开始时间',
                endPlaceholder: '结束时间',
                format: 'yyyy-MM-dd',
                readonly: false,
                clearable: true,
                default: ''
            },
            {
                type: 'areaselect',
                label: '省市区',
                icon: 'learun-icon-provinces',
                span: 24,
                placeholder: '请选择',
                display: true,

                default: ''
            }
            , {
                type: 'layerbmap',
                label: '地图选择',
                icon: 'learun-icon-map',
                span: 24,
                placeholder: '请输入地址',
                display: true,

                bindaddr: '',
                bindaddrpoint: '',
                height: 504,
                width: 960,

            }


        ]
    },
    {
        title: '上传组件',
        list: [
            {
                type: 'upload',
                label: '文件上传',
                icon: 'learun-icon-file-upload',
                span: 24,
                display: true,
                maxSize: '2',
                sizeType: 'MB',
                limit: 1,
                default: ''
            }
            , {
                type: 'uploadimg',
                label: '图片上传',
                icon: 'learun-icon-image-upload',
                span: 24,
                display: true,
                listType: 'picture-card',
                maxSize: '2',
                sizeType: 'MB',
                limit: 9,
                default: ''
            }
        ]
    },
    {
        title: '系统组件',
        list: [
            {
                type: 'guid',
                label: 'GUID主键',
                icon: 'learun-icon-guid',
                span: 24,
                display: false,
                default: ''
            },
            {
                type: 'companySelect',
                label: '公司选择',
                icon: 'learun-icon-co',
                span: 24,
                display: true,
                isLogin: false,
                default: ''
            },
            {
                type: 'departmentSelect',
                label: '部门选择',
                icon: 'learun-icon-department',
                span: 24,
                display: true,
                isLogin: false,
                default: ''
            },
            {
                type: 'userSelect',
                label: '人员选择',
                icon: 'learun-icon-user',
                span: 24,
                display: true,
                isLogin: false,
                multiple: false,
                default: ''
            },

            {
                type: 'company',
                label: '所属公司',
                icon: 'learun-icon-co',
                span: 24,
                display: false,
                default: ''
            },
            {
                type: 'department',
                label: '所属部门',
                icon: 'learun-icon-department',
                span: 24,
                display: false,
                default: ''
            },
            {
                type: 'createuser',
                label: '创建人员',
                icon: 'learun-icon-user',
                span: 24,
                display: false,
                default: ''
            },
            {
                type: 'modifyuser',
                label: '修改人员',
                icon: 'learun-icon-user',
                span: 24,
                display: false,
                default: ''
            },
            {
                type: 'createtime',
                label: '创建时间',
                icon: 'learun-icon-time',
                span: 24,
                display: false,
                default: ''
            },
            {
                type: 'modifytime',
                label: '修改时间',
                icon: 'learun-icon-time',
                span: 24,
                display: false,
                default: ''
            },
            {
                type: 'encode',
                label: '单据编码',
                icon: 'learun-icon-doc-code',
                span: 24,
                display: true,
                default: '',
                code: ''
            }
        ]
    },
    {
        title: '视图组件',
        list: [
            {
                type: 'viewtable',
                label: '视图表格',
                icon: 'learun-icon-view-form',
                span: 24,

                display: true,
                paramFiled: '',
                dataCode: '',
                columns: []
            },
        ]
    },
    {
        title: '操作组件',
        list: [
            {
                type: 'btn',
                label: '按钮组件',
                icon: 'learun-icon-button',
                span: 24,
                display: true,
                size: 'mini',
                plain: false,
                round: false,
                circle: false,
                myIcon: '',
                myType: ''
            },
        ]
    },
    {
        title: '其它组件',
        list: [
            {
                type: 'icon',
                label: '图标',
                icon: 'learun-icon-icon',
                span: 24,
                display: true,
                default: '',
            },
            {
                type: 'rate',
                label: '评分',
                icon: 'learun-icon-star',
                span: 24,
                display: true,
                max: 5,
                disabled: false,
                allowHalf: false,
                lowThreshold: 2,
                highThreshold: 4,
                colors: ['#F7BA2A', '#F7BA2A', '#F7BA2A'],
                voidColor: '#C6D1DE',
                disabledVoidColor: '#EFF2F7',
                iconClasses: ['el-icon-star-on', 'el-icon-star-on', 'el-icon-star-on'],
                voidIconClass: 'el-icon-star-off',
                disabledVoidIconClass: 'el-icon-star-on',
                showText: false,
                showScore: false,
                textColor: '#1F2D3D',
                texts: ['极差', '失望', '一般', '满意', '惊喜'],
                default: 0,
            }
            , {
                type: 'switch',
                label: '开关',
                icon: 'learun-icon-on-off',
                span: 24,
                display: true,
                disabled: false,
                width: 40,
                default: false,
                activeIconClass: '',
                inactiveIconClass: '',
                activeText: '',
                inactiveText: '',
                valueType: 'boolean',
                activeValue: 'true',
                inactiveValue: 'false',
                activeColor: '#409EFF',
                inactiveColor: '#C0CCDA'
            }
            , {
                type: 'slider',
                label: '滑块',
                icon: 'learun-icon-sliding',
                span: 24,
                display: true,
                default: 0,
                min: 0,
                max: 100,
                step: 1,
                showInput: false,
                showInputControls: true,
                showStops: false,
                showTooltip: true
            }
            , {
                type: 'color',
                label: '颜色选择',
                icon: 'learun-icon-color',
                span: 24,
                default: '',
                display: true,
                disabled: false,
            }

        ]
    }
]