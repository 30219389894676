<template>
  <div>
    <el-form-item :label="$t('是否多选')">
        <el-switch v-model="data.multiple"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('登录者')">
        <el-switch v-model="data.isLogin" ></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否必填')">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-userSelect",
  props: ['data'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

