<template>
  <div>
    <el-divider>{{$t('私有属性')}}</el-divider>
    <el-form-item :label="$t('默认值')">
        <el-switch v-bind="$deepClone(data)" v-model="data.default" >
        </el-switch>
    </el-form-item> 
    <el-form-item :label="$t('宽度(像素)')">
      <el-input-number v-model="data.width"
                       controls-position="right"
                       :placeholder="$t('请输入')"></el-input-number>
    </el-form-item>
    <el-form-item :label="$t('打开图标')">
      <l-input-icon v-model="data.activeIconClass"
                       :iconList="iconList"
                       :placeholder="$t('请选择')"></l-input-icon>
    </el-form-item>
    <el-form-item :label="$t('关闭图标')">
      <l-input-icon v-model="data.inactiveIconClass"
                       :iconList="iconList"
                       :placeholder="$t('请选择')"></l-input-icon>
    </el-form-item>
    <el-form-item :label="$t('打开文字')">
      <el-input v-model="data.activeText"
                       :placeholder="$t('请输入')"></el-input>
    </el-form-item>
    <el-form-item :label="$t('关闭文字')">
      <el-input v-model="data.inactiveText"
                       :placeholder="$t('请输入')"></el-input>
    </el-form-item>
    <el-form-item :label="$t('打开值')">
      <el-input v-model="data.activeValue">
         <!--<el-select v-model="data.valueType" slot="append" placeholder="请选择" class="s-w-72" >
            <el-option label="布尔" value="boolean"></el-option>
            <el-option label="字串" value="string"></el-option>
            <el-option label="数字" value="number"></el-option>
        </el-select>-->
      </el-input>
    </el-form-item>
    <el-form-item :label="$t('关闭值')">
      <el-input v-model="data.inactiveValue">
        <!--<el-select v-model="data.valueType" slot="append" placeholder="请选择" class="s-w-72" >
            <el-option label="布尔" value="boolean"></el-option>
            <el-option label="字串" value="string"></el-option>
            <el-option label="数字" value="number"></el-option>
        </el-select>-->
      </el-input>
    </el-form-item>
    <el-form-item :label="$t('打开颜色')">
      <l-input-color v-model="data.activeColor"
                       :placeholder="$t('请输入')"></l-input-color>
    </el-form-item>
    <el-form-item :label="$t('关闭颜色')">
      <l-input-color v-model="data.inactiveColor"
                       :placeholder="$t('请输入')"></l-input-color>
    </el-form-item>
    <el-form-item :label="$t('是否必填')">
      <el-switch v-model="data.required" ></el-switch>
    </el-form-item>   
    <el-form-item :label="$t('是否禁用')">
      <el-switch v-model="data.disabled"></el-switch>
    </el-form-item> 
    <el-form-item :label="$t('是否可见')">
      <el-switch v-model="data.display"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
    name: "config-switch",
    props: ['data'],
    data () {
        return {
        }
    },
    computed: {
        iconList(){
            if(this.lr_icons){
              return this.lr_icons
            }
            else{
              return []
            }
        },
    },
    methods: {
    }
}
</script>

