<template>
  <el-tabs v-model="tabActive" type="card">
    <el-tab-pane
      v-for="(item, index) in data.tabList"
      :key="index"
      :label="item.text"
      :name="'' + index"
    >
      <l-form-wraper :myComponents="item.children" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  name: "tab-wraper",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    tabActive: {
      get() {
        return this.data.active;
      },
      set(val) {
        this.data.active = val;
      },
    },
  },
  methods: {
    
  },
};
</script>