<template>
  <div
    v-if="ready"
    class="l-rblock l-form-viewer"
    style="padding: 8px 8px 0 8px"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      :size="myFormInfo.size"
      :labelPosition="myFormInfo.labelPosition"
      :label-width="
        myFormInfo.labelWidth ? `${myFormInfo.labelWidth}px` : '80px'
      "
    >
      <input type="text" style="display: none" />
      <div :class="['l-auto-window', { 'only-tabs': showTabList.length <= 1 }]">
        <el-tabs v-model="formActiveName" type="card">
          <el-tab-pane
            class="l-tabs-container"
            v-for="(item, index) in showTabList"
            :key="index"
            :label="item.text"
            :name="'tab' + index"
          >
            <el-row :gutter="myFormInfo.gutter">
              <div class="l-rblock">
                <l-form-wraper :myComponents="item.components" />
              </div>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "l-form-viewer",
  provide() {
    return {
      formViewer: this,
    };
  },
  props: {
    formInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isAuth: {
      type: Boolean,
      default: false,
    },
    isRead: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formActiveName: "tab0",

      formData: {},
      oldFormData: null,

      originalTabList: [],
      myFormInfo: {},

      gridtables: [],
      rules: {},
      components: {},

      isUpdate: false,
      loadingObj: null,

      upCtrls: {}, // 级联组件之间的集合

      gridtableRefs: {},

      ready: false,
    };
  },
  computed: {
    tabList() {
      /**对表单字段调整根据权限信息 */
      if (this.isAuth && this.lr_pageAuthInfo && this.lr_pageAuthInfo.isAuth) {
        const tabList = [];
        const authData = this.lr_pageAuthInfo.data.forms;
        const authMap = {};

        authData.forEach((item) => {
          const itemList = item.split(",");
          const propList = itemList[0].split("|");
          if (propList.length > 1) {
            // 表格
            authMap[propList[0]] = authMap[propList[0]] || {};
            authMap[propList[0]][propList[1]] = itemList[1];
          } else {
            // 表单字段
            authMap[itemList[0]] = itemList[1];
          }
        });

        // 放置隐藏组件
        const hideComponents = [];

        this.originalTabList &&
          this.originalTabList.forEach((tab) => {
            const newTab = {
              text: tab.text,
              components: [],
            };

            tab.components.forEach((component) => {
              if (
                !["gridtable", "card", "tab", "collapse"].includes(
                  component.type
                )
              ) {
                if (!component.display) {
                  hideComponents.push(component);
                } else if (authMap[component.prop]) {
                  if (authMap[component.prop] != "2") {
                    component.disabled = true;
                    //component.readonly = true
                  }
                  newTab.components.push(component);
                } else if (
                  [
                    "guid",
                    "company",
                    "department",
                    "createuser",
                    "modifyuser",
                    "createtime",
                    "modifytime",
                    "",
                  ].includes(component.type)
                ) {
                  component.display = false;
                  hideComponents.push(component);
                }
              } else if (["gridtable"].includes(component.type)) {
                if (authMap[`${component.prop}_add`]) {
                  if (authMap[`${component.prop}_add`] == "2") {
                    component.isAddBtn = true;
                  } else {
                    component.isAddBtn = false;
                  }
                }

                if (authMap[`${component.prop}_remove`]) {
                  if (authMap[`${component.prop}_remove`] == "2") {
                    component.isRemoveBtn = true;
                  } else {
                    component.isRemoveBtn = false;
                  }
                }

                if (authMap[component.prop]) {
                  newTab.components.push(component);
                  component.children.forEach((cell) => {
                    if (authMap[component.prop][cell.prop]) {
                      if (authMap[component.prop][cell.prop] != "2") {
                        cell.disabled = true;
                        //cell.readonly = true
                      }
                    } else {
                      cell.display = false;
                    }
                  });
                }
              } else if (
                authMap[component.prop] ||
                ["card", "tab", "collapse"].includes(component.type)
              ) {
                newTab.components.push(component);
              }
            });
            // 不可见的组件单独放置
            if (newTab.components.length > 0) {
              tabList.push(newTab);
            }
          });

        if (tabList.length > 0) {
          tabList[0].components.push(...hideComponents);
        }

        return tabList;
      } else if (this.isWfForm) {
        // 表单的流程权限
        const tabList = [];

        // 放置隐藏组件
        const hideComponents = [];

        this.originalTabList &&
          this.originalTabList.forEach((tab) => {
            const newTab = {
              text: tab.text,
              components: [],
            };

            tab.components.forEach((component) => {
              if (
                !["gridtable", "card", "tab", "collapse"].includes(
                  component.type
                )
              ) {
                if (!component.display) {
                  hideComponents.push(component);
                } else if (
                  this.authFieldsMap[component.prop] &&
                  (this.authFieldsMap[component.prop].isEdit ||
                    this.authFieldsMap[component.prop].isLook)
                ) {
                  if (!this.authFieldsMap[component.prop].isEdit) {
                    component.disabled = true;
                  }
                  newTab.components.push(component);
                } else if (
                  [
                    "guid",
                    "company",
                    "department",
                    "createuser",
                    "modifyuser",
                    "createtime",
                    "modifytime",
                    "",
                  ].includes(component.type)
                ) {
                  component.display = false;
                  hideComponents.push(component);
                }
              } else if (["gridtable"].includes(component.type)) {
                if (this.authFieldsMap[`${component.prop}_add`]) {
                  if (this.authFieldsMap[`${component.prop}_add`].isEdit) {
                    component.isAddBtn = true;
                  } else {
                    component.isAddBtn = false;
                  }
                }

                if (this.authFieldsMap[`${component.prop}_remove`]) {
                  if (this.authFieldsMap[`${component.prop}_remove`].isEdit) {
                    component.isRemoveBtn = true;
                  } else {
                    component.isRemoveBtn = false;
                  }
                }

                if (this.authFieldsMap[`${component.prop}_required`]) {
                  if (
                    this.authFieldsMap[`${component.prop}_required`].required
                  ) {
                    component.required = true;
                  } else {
                    component.required = false;
                  }
                }

                component.children.forEach((cell) => {
                  if (
                    this.authFieldsMap[cell.prop] &&
                    (this.authFieldsMap[cell.prop].isEdit ||
                      this.authFieldsMap[cell.prop].isLook)
                  ) {
                    if (!this.authFieldsMap[cell.prop].isEdit) {
                      cell.disabled = true;
                    }
                    if (
                      newTab.components.findIndex(
                        (t) => t.prop == component.prop
                      ) == -1
                    ) {
                      newTab.components.push(component);
                    }
                  } else {
                    cell.display = false;
                  }
                });
              } else if (
                ["card", "collapse", "tab"].includes(component.type) ||
                (this.authFieldsMap[component.prop] &&
                  (this.authFieldsMap[component.prop].isEdit ||
                    this.authFieldsMap[component.prop].isLook))
              ) {
                newTab.components.push(component);
              }
            });
            // 不可见的组件单独放置

            if (newTab.components.length > 0) {
              tabList.push(newTab);
            }
          });

        if (tabList.length > 0) {
          tabList[0].components.push(...hideComponents);
        }

        return tabList;
      } else {
        return this.originalTabList || [];
      }
    },
    showTabList() {
      return this.toShowData(this.tabList);
    },
  },
  mounted() {},
  methods: {
    async init(postData) {
      this.ready = false;
      this.gridtableRefs = {};
      this.isUpdate = !this.$validatenull(postData);

      this.myFormInfo = {};
      this.oldFormData = null;
      this.formData = {};

      const gridtables = [];
      const rules = {};
      const components = {};

      const formInfo = this.$deepClone(this.formInfo);

      // 获取表单需要校验重复的数据

      // 设置对应数据值
      formInfo.tabList &&
        formInfo.tabList.forEach((tab) => {
          tab.components.forEach((component) => {
            components[component.prop] = component;
            if (
              !["viewtable", "card", "btn", "tab", "collapse"].includes(
                component.type
              )
            ) {
              this.$set(
                this.formData,
                component.prop,
                this.getFormDataValue(component, postData || {}, this.isUpdate)
              );

              if (["gridtable"].includes(component.type)) {
                gridtables.push(component);
                component.children.forEach((col) => {
                  components[col.prop] = col;
                  if (col.upCtrl) {
                    this.upCtrls[col.upCtrl] = this.upCtrls[col.upCtrl] || [];
                    this.upCtrls[col.upCtrl].push(col.prop);
                  }
                });
              } else {
                if (component.upCtrl) {
                  this.upCtrls[component.upCtrl] =
                    this.upCtrls[component.upCtrl] || [];
                  this.upCtrls[component.upCtrl].push(component.prop);
                }

                if (component.required) {
                  rules[component.prop] = [];
                  rules[component.prop].push({
                    required: true,
                    message: `请输入${component.label}`,
                    trigger: "blur",
                  });
                }
                if (component.patterns && component.patterns.length > 0) {
                  rules[component.prop] = rules[component.prop] || [];
                  component.patterns.forEach((pattern) => {
                    rules[component.prop].push({
                      pattern: eval(pattern.reg),
                      message: pattern.msg,
                      trigger: "blur",
                    });
                  });
                }
              }
            } /* else {
              
            }*/
          });
        });

      this.gridtables = gridtables;
      this.rules = rules;
      this.components = components;

      this.myFormInfo = formInfo;
      this.originalTabList = formInfo.tabList;
      this.formActiveName = "tab0";

      // 加载脚本
      await this.beforeFormSetData();

      if (this.isUpdate) {
        this.oldFormData = this.$deepClone(this.formData);
      }

      this.$nextTick(() => {
        this.ready = true;
      });
    },
    resetFormData() {
      this.ready = false;
    },
    // 校验表单数据
    validateForm() {
      return new Promise((resolve) => {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            const gridtables = this.gridtables;
            let gridValid = true;
            try {
              gridtables.forEach((item) => {
                if (this.gridtableRefs[item.prop][0]) {
                  gridValid = this.gridtableRefs[item.prop][0].validate(item);
                }
                if (!gridValid) {
                  throw new Error();
                }
              });
            } catch (error) {}
            if (gridValid) {
              gridValid = await this.validateEvent();
            }

            resolve(gridValid);
          } else {
            this.$message({
              type: "error",
              message: this.$t(`表单有未正确填写项,请检查!`),
            });
            resolve(false);
          }
        });
      });
    },
    getForm() {
      // 获取表单数据
      return this.$deepClone(this.formData);
    },
    async setForm(postData) {
      return this.init(postData);
    },
    // 比对数据修改
    getDiffFormData() {
      const diffFormData = [];
      if (this.oldFormData == null) {
        return diffFormData;
      }
      for (let key in this.oldFormData) {
        const componentM = this.components[key];
        const oldValue = this.oldFormData[key];
        const newValue = this.formData[key];
        if (
          componentM &&
          ![
            "gridtable",
            "divider",
            "lable",
            "upload",
            "uploadimg",
            "company",
            "department",
            "createuser",
            "modifyuser",
            "createtime",
            "modifytime",
            "viewtable",
            "card",
            "collapse",
            "tab",
            "btn",
          ].includes(componentM.type) &&
          oldValue != newValue &&
          !componentM.isNotSave
        ) {
          const dataPoint = {
            // 数据变动
            table_Name: componentM.table,
            tableField: componentM.field,
            formColumn: componentM.label,
            oldVal: oldValue,
            newVal: newValue,
            oldShowVal: "",
            newShowVal: "",
            creator: this.loginInfo.f_RealName + "/" + this.loginInfo.f_Account,
          };
          dataPoint.oldShowVal = this.getLabel(key, this.oldFormData);
          dataPoint.newShowVal = this.getLabel(key, this.formData);

          diffFormData.push(dataPoint);
        }
      }
      return diffFormData;
    },
    findTreeItem(arr, res) {
      arr.forEach((t) => {
        res.push({ value: t.value, label: t.label });
        if (t.children) {
          this.findTreeItem(t.children, res);
        }
      });
    },

    // 表单调整显示组件
    toShowData(data) {
      // 转化成显示数据
      const tabList = this.$deepClone(data);
      const pMap = {};
      tabList.forEach((tab) => {
        const componentList = [];
        tab.components.forEach((component) => {
          if (["card"].includes(component.type)) {
            pMap[component.prop] = pMap[component.prop] || [];
            component.children = pMap[component.prop];
          } else if (["tab", "collapse"].includes(component.type)) {
            component.active = "0";
            if (component.type == "collapse") {
              component.active = [];
            }
            component.tabList.forEach((tab, index) => {
              const tabProp = `${component.prop}.${index}`;
              pMap[tabProp] = pMap[tabProp] || [];
              tab.children = pMap[tabProp];
              if (component.type == "collapse") {
                component.active.push("" + index);
              }
            });
          }

          if (["card", "tab", "collapse"].includes(component.ptype)) {
            pMap[component.pid] = pMap[component.pid] || [];
            pMap[component.pid].push(component);
            delete component.pid;
            delete component.ptype;
          } else {
            componentList.push(component);
          }
        });
        tab.components = componentList;
      });
      this.toFilterCard(tabList);
      return tabList;
    },
    toFilterCard(tabList) {
      tabList.forEach((tab) => {
        const componentList = [];
        tab.components.forEach((component) => {
          if (["card"].includes(component.type)) {
            component.children = this.toFilterCardSub(component.children);
            if (component.children.length > 0) {
              componentList.push(component);
            }
          } else if (["tab", "collapse"].includes(component.type)) {
            const tabList = [];
            component.tabList.forEach((tabItem) => {
              tabItem.children = this.toFilterCardSub(tabItem.children);
              if (tabItem.children.length > 0) {
                tabList.push(tabItem);
              }
            });
            component.tabList = tabList;
            if (component.tabList.length > 0) {
              componentList.push(component);
            }
          } else {
            componentList.push(component);
          }
        });
        tab.components = componentList;
      });
    },
    toFilterCardSub(cardList) {
      const list = [];
      cardList.forEach((cardComponent) => {
        if (cardComponent.type == "card") {
          cardComponent.children = this.toFilterCardSub(cardComponent.children);
          if (cardComponent.children.length > 0) {
            list.push(cardComponent);
          }
        } else if (["tab", "collapse"].includes(cardComponent.type)) {
          const tabList = [];
          cardComponent.tabList.forEach((tabItem) => {
            tabItem.children = this.toFilterCardSub(tabItem.children);
            if (tabItem.children.length > 0) {
              tabList.push(tabItem);
            }
          });
          cardComponent.tabList = tabList;
          if (cardComponent.tabList.length > 0) {
            list.push(cardComponent);
          }
        } else {
          list.push(cardComponent);
        }
      });
      return list;
    },
    // 获取表单初始值的设置
    getFormDataValue(component, data, isUpdate) {
      const res = [];
      if (component.type == "gridtable") {
        const gridDefault = this.getGridDefault(component);
        let tableData = data[component.table];

        if (tableData == undefined) {
          tableData = gridDefault;
        }

        for (let i = 0, len = tableData.length; i < len; i++) {
          const row = tableData[i];
          const rowTmp = {};
          for (let j = 0, jlen = component.children.length; j < jlen; j++) {
            const cell = component.children[j];
            if (cell.field) {
              let cellValue = row[cell.field.toLowerCase()] || row[cell.prop];
              if (!this.$validatenull(cellValue)) {
                if (["slider", "rate"].includes(cell.type)) {
                  cellValue = parseInt(cellValue);
                  if (isNaN(cellValue)) {
                    cellValue = 0;
                  }
                }
                rowTmp[cell.prop] = cellValue;
              }
            } else {
              if (gridDefault.length > i) {
                let cellValue = gridDefault[i][cell.prop];
                if (!this.$validatenull(cellValue)) {
                  rowTmp[cell.prop] = cellValue;
                }
              }
            }
          }
          res.push(rowTmp);
        }
        return res;
      } else {
        let resValue;

        if (data[component.table] && data[component.table][0]) {
          resValue = data[component.table][0][component.field.toLowerCase()];
        } else if (!isUpdate) {
          resValue = component.default;
          if (component.isLogin && this.loginInfo) {
            switch (component.type) {
              case "companySelect":
                resValue = this.loginInfo.f_CompanyId;
                break;
              case "departmentSelect":
                resValue = this.loginInfo.f_DepartmentId;
                break;
              case "userSelect":
                resValue = this.loginInfo.f_UserId;
                break;
            }
          }
        }

        if (["switch"].includes(component.type)) {
          resValue =
            this.toValueType(component.valueType, resValue) ||
            this.toValueType(component.valueType, component.inactiveValue);
        } else if (
          ["select", "radio", "checkbox", "treeselect", "layerselect"].includes(
            component.type
          ) &&
          component.dataType == "2" &&
          !this.$validatenull(resValue)
        ) {
          resValue = resValue + "";
        } else if (
          ["slider", "rate"].includes(component.type) &&
          !this.$validatenull(resValue)
        ) {
          resValue = parseInt(resValue);
          if (isNaN(resValue)) {
            resValue = 0;
          }
        }

        return resValue;
      }
    },
    toValueType(type, value) {
      if (!this.$validatenull(value) && !this.$validatenull(type)) {
        switch (type) {
          case "boolean":
            return value == "true";
          case "string":
            return value;
          case "number":
            return Number(value);
        }
      }
    },

    // 获取设置的默认行数据
    getGridDefault(component) {
      const dataSource = [];
      if (component.isRowFixed) {
        const defaultList = component.dataSource || [];
        for (let i = 0; i < component.rowNum; i++) {
          const row = defaultList[i];
          const point = {};
          component.children.forEach((col) => {
            if (row) {
              point[col.prop] = row[col.prop] || col.default || undefined;
            } else {
              point[col.prop] = col.default || undefined;
              if (!col.display) {
                switch (col.type) {
                  case "":
                    point[col.prop] = `learun_code_${col.code}|${this.$uuid()}`;
                    break;
                  case "guid":
                    point[col.prop] = this.$uuid();
                    break;
                  case "company":
                    if (this.loginInfo) {
                      point[col.prop] = this.loginInfo.f_CompanyId;
                    }
                    break;
                  case "department":
                    if (this.loginInfo) {
                      point[col.prop] = this.loginInfo.f_DepartmentId;
                    }
                    break;
                  case "createuser":
                    if (this.loginInfo) {
                      point[col.prop] = this.loginInfo.f_UserId;
                    }
                    break;
                  case "modifyuser":
                    if (this.loginInfo) {
                      point[col.prop] = this.loginInfo.f_UserId;
                    }
                    break;
                  case "createtime":
                    point[col.prop] = this.$getDayTime();
                    break;
                  case "modifytime":
                    point[col.prop] = this.$getDayTime();
                    break;
                }
              }
            }
          });
          dataSource.push(point);
        }
      }
      return dataSource;
    },

    // 表单脚本处理方法
    getEventParams({ prop, data, tableProp, tableIndex, tableRow, callback }) {
      // 获取脚本参数
      const loginUser = this.loginInfo;
      const params = {
        prop: prop,
        data: data,
        tableProp: tableProp,
        tableIndex: tableIndex,
        isUpdate: this.isUpdate,
        get: this.getValue,
        set: this.setValue,
        getLabel: this.getLabel,
        setRequired: this.setRequired,
        setDisabled: this.setDisabled,
        setHide: this.setHide,
        loading: this.showLoading,
        hideLoading: this.hideLoading,
        message: this.showMessage,
        httpGet: this.httpGet,
        httpPost: this.httpPost,
        httpDelete: this.httpDelete,
        httpPut: this.httpPut,
        loginUser: loginUser,
        callback: callback,
      };
      if (tableProp) {
        params.getLabel = (id) => {
          return this.getLabel(id, tableRow);
        };
      }

      return params;
    },
    getEvent(strEvent) {
      // 获取事件方法
      if (!this.$validatenull(strEvent)) {
        /*if (strEvent.indexOf("=>") != -1) {
          // 表示是老版本，提示其修改为新的版本
          console.warn("当前脚本不支持ES6语法，只支持ES5语法");
          return { res: false, msg: "脚本没有更新为最新的版本！" };
        } else {
          strEvent = `(function(){function fn(learun) {${strEvent}} return fn})()`;
          return this.$getFunction(strEvent);
        }*/
        strEvent = `async (learun) => {${strEvent}}`;
        return this.$getFunction(strEvent);
      } else {
        return { res: false, msg: "没设置脚本函数！" };
      }
    },
    beforeFormSetData() {
      // 表单初始化，新增和编辑都是执行的
      return new Promise(async (resolve) => {
        const myEvent = this.getEvent(this.myFormInfo.beforeSetData);
        if (myEvent.res) {
          const res = await myEvent.fn(
            this.getEventParams({
              callback: (res) => {
                // 兼容异步回调方法
                resolve(res);
              },
            })
          );
          if (res != "callback") {
            resolve(res);
          }
        } else {
          resolve(true);
        }
      });
    },
    afterSaveEvent(formData) {
      // 表单初始化，新增和编辑都是执行的
      return new Promise(async (resolve) => {
        const myEvent = this.getEvent(this.myFormInfo.afterSaveEvent);
        if (myEvent.res) {
          this.formData = this.$deepClone(formData);
          const res = await myEvent.fn(
            this.getEventParams({
              callback: (res) => {
                // 兼容异步回调方法
                resolve(res);
              },
            })
          );
          if (res != "callback") {
            resolve(res);
          }
        } else {
          resolve(true);
        }
      });
    },
    validateEvent() {
      // 表单初始化，新增和编辑都是执行的
      return new Promise(async (resolve) => {
        const myEvent = this.getEvent(this.myFormInfo.afterValidateForm);
        if (myEvent.res) {
          const res = await myEvent.fn(
            this.getEventParams({
              callback: (res) => {
                // 兼容异步回调方法
                resolve(res);
              },
            })
          );
          if (res != "callback") {
            resolve(res);
          }
        } else {
          resolve(true);
        }
      });
    },
    async formDataChangeEvent(prop, data) {
      // 表单数据改变执行脚本
      const myEvent = this.getEvent(this.myFormInfo.changeDataEvent);
      if (myEvent.res) {
        await myEvent.fn(this.getEventParams({ prop, data }));
      }
    },
    async tableDataChangeEvent(
      prop,
      data,
      tableComponent,
      tableIndex,
      tableRow
    ) {
      if (tableComponent) {
        const myEvent = this.getEvent(tableComponent.changeDataEvent);
        if (myEvent.res) {
          await myEvent.fn(
            this.getEventParams({
              prop,
              data,
              tableProp: tableComponent.prop,
              tableIndex,
              tableRow,
            })
          );
        }
      }
    },
    async componentDataChangeEvent(prop, data) {
      // 组件数据改变执行脚本
      const component = this.components[prop];
      if (component) {
        const myEvent = this.getEvent(component.changeCode);
        if (myEvent.res) {
          await myEvent.fn(this.getEventParams({ prop, data }));
        }
      }
    },
    async componentBtnClick(component, tableComponent, tableIndex, tableRow) {
      const myEvent = this.getEvent(component.clickCode);
      if (myEvent.res) {
        await myEvent.fn(
          this.getEventParams({
            tableProp: tableComponent ? tableComponent.prop : "",
            tableIndex,
            tableRow,
          })
        );
      }
    },
    async handleItemChange(
      { prop, obj },
      tableComponent,
      tableIndex,
      tableRow
    ) {
      // 子表改变事件
      if (tableComponent) {
        this.tableDataChangeEvent(
          prop,
          obj,
          tableComponent,
          tableIndex,
          tableRow
        );
      } else {
        await this.componentDataChangeEvent(prop, obj);
        await this.formDataChangeEvent(prop, obj);
      }
    },

    // 表单提供给脚本的方法
    getValue(path) {
      const paths = path.split(".");
      let data = this.formData;
      for (let i = 0, len = paths.length; i < len; i++) {
        if (data) {
          data = data[paths[i]];
        }
      }
      return data;
    },
    setValue({ path, value, type }) {
      if (type == "addTable") {
        this.formData[path].push(value);
      } else if (type == "deleteTable") {
        const paths = path.split(".");
        this.formData[paths[0]].splice(paths[1], 1);
      } else {
        const paths = path.split(".");
        if (paths.length == 2) {
          this.formData[paths[0]] &&
            this.$set(this.formData[paths[0]], paths[1], value);
        } else if (paths.length == 3 && this.formData[paths[0]]) {
          this.formData[paths[0]] &&
            this.formData[paths[0]][paths[1]] &&
            this.$set(this.formData[paths[0]][paths[1]], paths[2], value);
        } else {
          this.$set(this.formData, path, value);
        }
      }
    },
    getLabel(id, formData) {
      if (formData == null) {
        formData = this.formData;
      }
      const v = formData[id];

      const componentM = this.components[id];
      switch (componentM.type) {
        case "radio":
        case "select":
        case "treeselect":
        case "layerselect":
        case "checkbox":
        case "selectMultiple":
          if (componentM.dataType == "3") {
            if (componentM.upCtrl) {
              if (formData && formData[componentM.upCtrl]) {
                const upCtrlVal = formData[componentM.upCtrl];
                return this.lr_dataSourceName(
                  this.lr_dataSourceDataByParamter[
                    `${componentM.dataCode}_${upCtrlVal}`
                  ],
                  v,
                  componentM.dataValueKey,
                  componentM.dataLabelKey,
                  componentM.dataCode
                );
              }
            } else {
              return this.lr_dataSourceName(
                this.lr_dataSourceData[componentM.dataCode],
                v,
                componentM.dataValueKey,
                componentM.dataLabelKey,
                componentM.dataCode
              );
            }
          } else if (componentM.dataType == "2") {
            return this.lr_dataItemName(
              this.lr_dataItem[componentM.dataCode],
              v
            );
          } else {
            const dataSource = [];
            this.findTreeItem(componentM.options, dataSource); //静态数据
            return this.lr_dataSourceName(
              dataSource,
              v,
              "value",
              "label",
              componentM.prop
            );
          }
          break;
        case "companySelect":
          return this.lr_loadCompanyName(v);
        case "departmentSelect":
          return this.lr_departmentNameByOne(v);
        case "userSelect":
          return this.lr_userName(v);
        case "areaselect":
          return this.lr_areasName(v);
        case "layerbmap":
          return v.split(",")[0];
        case "company":
          return this.lr_loadCompanyName(v);
        case "department":
          return this.lr_departmentNameByOne(v);
        case "datetime":
            return window.$formatDate(v, "yyyy-MM-dd");
        case "createuser":
            return this.lr_userName(v);

      }
      return v;
    },
    setRequired(prop, isRequired = true) {
      const component = this.components[prop];
      if (component) {
        this.$set(component, "required", isRequired);
        if (!component.subfield) {
          const rules = (this.rules[prop] || []).filter((t) => !t.required);
          if (isRequired) {
            rules.push({
              required: true,
              message: `请输入${component.label}`,
              trigger: "blur",
            });
          }
          this.$set(this.rules, component.prop, rules);
        }
      }
    },
    setDisabled(prop, isDisabled = true) {
      const component = this.components[prop];
      if (component) {
        this.$set(component, "disabled", isDisabled);
      }
    },
    setHide(prop, isHide = true) {
      const component = this.components[prop];
      if (component) {
        this.$set(component, "display", !isHide);
      }
    },
    showLoading(text) {
      this.loadingObj = this.$loading({
        lock: true,
        text: text || "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.4)",
      });
    },
    hideLoading() {
      this.loadingObj && this.loadingObj.close();
    },
    showMessage(text, type = "warning") {
      this.$message({
        type: type,
        message: text,
      });
    },
    async httpGet({ url, params, callback }) {
      const res = await this.$awaitWraper(
        window.$axios({
          url: `${url}`,
          method: "get",
          params,
        })
      );
      callback && callback(res);
    },
    async httpPost({ url, params, data, callback }) {
      const res = await this.$awaitWraper(
        window.$axios({
          url: `${url}`,
          method: "post",
          params,
          data,
        })
      );
      callback && callback(res);
    },
    async httpPut({ url, params, data, callback }) {
      const res = await this.$awaitWraper(
        window.$axios({
          url: `${url}`,
          method: "put",
          params,
          data,
        })
      );
      callback && callback(res);
    },
    async httpDelete({ url, params, data, callback }) {
      const res = await this.$awaitWraper(
        window.$axios({
          url: `${url}`,
          method: "delete",
          params,
          data,
        })
      );
      callback && callback(res);
    },

    getPrintData() {
      const formData = this.getForm();
      for (let key in formData) {
        const componentM = this.components[key];

        if (formData[key] instanceof Array) {
          if (componentM.table) {
            formData[componentM.table] = [];
          }
          formData[key].forEach((row) => {
            const _row = {};
            for (let rowid in row) {
              if (rowid !== "_isHover") {
                row[rowid] = this.getLabel(rowid, row);
                const componentRow = this.components[rowid];
                if (componentRow.field) {
                  _row[componentRow.field] = row[rowid];
                }
              }
            }
            if (formData[componentM.table]) {
              formData[componentM.table].push(_row);
            }
          });
        } else {
          formData[key] = this.getLabel(key, formData);
          if (componentM.table && componentM.field) {
            formData[componentM.table] = formData[componentM.table] || {};
            formData[componentM.table][componentM.field] = formData[key];
          }
        }
      }
      return formData;
    },
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>
