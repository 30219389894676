<template>
  <div class="l-rblock" style="padding: 8px; overflow: auto">
    <l-edit-table
      :required="config.required"
      :isAddBtn="false"
      :isRemoveBtn="false"
      :isShowNum="config.isShowNum"
      :columns="getTableColumns(config.children, config.mergeHeader)"
      :dataSource="dataSource"
      :classType="config.classType == '2' ? 'element' : 'adms'"
      columnsType="array"
      :merges="merges"
      :ref="config.prop"
    >
      <template v-for="column in config.children" v-slot:[column.prop]="scope">
        <form-item
          :key="column.prop"
          v-model="scope.row[column.prop]"
          :upCtrls="upCtrls"
          :formData="scope.row"
          :data="column"
        />
      </template>
    </l-edit-table>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      dataSource: [],
    };
  },
  computed: {
    config() {
      const config = this.$deepClone(this.data)
      config.children.forEach(t=>{
        if(t.type == 'input' && t.isNotSave){
          t.readonly = false
          t.type = 'textarea'
          t.isNotSave = false
        }
      })
      return config;
    },
    upCtrls() {
      const upCtrls = {};
      this.config.children.forEach((col) => {
        if (col.upCtrl) {
          upCtrls[col.upCtrl] = upCtrls[col.upCtrl] || [];
          upCtrls[col.upCtrl].push(col.prop);
        }
      });
      return upCtrls;
    },
    merges() {
      const res = [];
      if (
        this.config.isRowFixed &&
        this.config.isRowMerge &&
        this.config.classType == "1" &&
        this.config.mergeRows
      ) {
        this.config.mergeRows.forEach((item) => {
          res.push([item.col, item.row1, item.row2]);
        });
      }
      return res;
    },
  },
  methods: {
    // 编辑表格操作方法
    getTableColumns(children, mergeHeader) {
      const columns = [];

      const colMap = {};
      const headerMap = {};
      if (mergeHeader) {
        mergeHeader.forEach((t) => {
          if (t.value) {
            const values = t.value.split(",");
            values.forEach((v) => {
              colMap[v] = t.prop;
            });
            headerMap[t.prop] = t;
          }
        });
      }

      children.forEach((col) => {
        this.setTreeColumns(
          colMap[col.prop] || "0",
          headerMap,
          colMap,
          columns
        );
        columns.push({
          _id: col.prop,
          _pid: colMap[col.prop] || "0",
          id: col.prop,
          isHidden: !col.display,
          prop: col.prop,
          label: col.label,
          width: col.labelWidth,
          required: col.required,
          patterns: col.patterns,
        });
      });
      return columns;
    },
    setTreeColumns(pid, headerMap, colMap, columns) {
      const col = headerMap[pid];
      if (col) {
        delete headerMap[pid];
        columns.push({
          _id: col.prop,
          _pid: colMap[col.prop] || "0",
          id: col.prop,
          label: col.label,
          props: col.value.split(","),
        });
        this.setTreeColumns(
          colMap[col.prop] || "0",
          headerMap,
          colMap,
          columns
        );
      }
    },
    handleItemChange(data, tableComponent, tableIndex, tableRow) {
      return this.formViewer.handleItemChange(
        data,
        tableComponent,
        tableIndex,
        tableRow
      );
    },

    setData(dataSource) {
      this.dataSource = dataSource;
    },
    getData() {
      return this.$deepClone(this.dataSource);
    },
  },
};
</script>