<template>
  <div class="component-config">
    <el-form
      v-if="this.config && Object.keys(this.config).length > 0"
      label-width="88px"
      label-position="left"
      size="mini"
    >
      <el-form-item label="ID">
        <el-input readonly="readonly" v-model="config.prop"></el-input>
      </el-form-item>
      <el-form-item
        v-if="['input'].includes(config.type)"
        :label="$t('不存表')"
      >
        <el-switch v-model="config.isNotSave" @change="notSaveChange" ></el-switch>
      </el-form-item>

      <el-form-item
        :label="$t('数据表')"
        v-if="!config.isNotSave && hasTableSetting"
      >
        <el-select
          v-model="config.table"
          :placeholder="$t('请选择')"
          @change="handleTableChange"
        >
          <el-option
            v-for="item in dbTables"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('字段名')"
        v-if="
          ![
            'gridtable',
            'divider',
            'lable',
            'viewtable',
            'card',
            'collapse',
            'tab',
            'btn',
          ].includes(config.type) && !config.isNotSave
        "
      >
        <l-select
          :options="getFields(config.table)"
          v-model="config.field"
          @change="handleFiledChange"
        >
        </l-select>
      </el-form-item>
      <el-form-item
        :label="config.type == 'btn' ? $t('按钮名称') : $t('标题名')"
        v-if="!['divider', 'lable', 'tab', 'collapse'].includes(config.type)"
      >
        <el-input v-model="config.label" :placeholder="$t('请输入')"></el-input>
      </el-form-item>
      <el-form-item
        :label="config.subfield ? $t('单元格宽度') : $t('标题宽度')"
        v-if="
          ![
            'gridtable',
            'divider',
            'lable',
            'viewtable',
            'card',
            'collapse',
            'tab',
            'btn',
          ].includes(config.type)
        "
      >
        <el-input-number
          v-model="config.labelWidth"
          controls-position="right"
          :placeholder="$t('请输入')"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        :label="config.subfield ? $t('单元格宽度') : ''"
        v-if="
          [
            'btn',
          ].includes(config.type) && config.subfield
        "
      >
        <el-input-number
          v-model="config.labelWidth"
          controls-position="right"
          :placeholder="$t('请输入')"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        :label="$t('占位提示')"
        v-if="
          ![
            'btn',
            'card',
            'collapse',
            'tab',
            'gridtable',
            'divider',
            'lable',
            'radio',
            'timerange',
            'datetimerange',
            'upload',
            'uploadimg',
            'guid',
            'rate',
            'company',
            'department',
            'modifytime',
            'modifyuser',
            'createtime',
            'createuser',
            'viewtable',
          ].includes(config.type)
        "
      >
        <el-input
          v-model="config.placeholder"
          :placeholder="$t('请输入')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('开始占位')"
        v-if="['timerange', 'datetimerange'].includes(config.type)"
      >
        <el-input
          v-model="config.startPlaceholder"
          :placeholder="$t('请输入')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('结束占位')"
        v-if="['timerange', 'datetimerange'].includes(config.type)"
      >
        <el-input
          v-model="config.endPlaceholder"
          :placeholder="$t('请输入')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('表单栅格')"
        v-if="
          !config.subfield &&
          !['gridtable', 'texteditor', 'viewtable'].includes(config.type)
        "
      >
        <el-slider v-model="config.span" :step="6" :min="6" :max="24" show-stops>
        </el-slider>
      </el-form-item>

      <component :is="getComponent" :data="config"></component>

      <template v-if="!config.subfield && !isNoScript || config.type == 'btn'">
        <el-divider
          v-if="
            [
              'btn',
              'radio',
              'checkbox',
              'select',
              'selectMultiple',
              'treeselect',
              'layerselect',
              'areaselect',
              'companySelect',
              'departmentSelect',
              'userSelect',
              'switch',
            ].includes(config.type)
          "
        >
          {{ $t("脚本") }}
        </el-divider>
        <div
          class="mt-8"
          v-if="
            [
              'radio',
              'checkbox',
              'select',
              'selectMultiple',
              'treeselect',
              'layerselect',
              'areaselect',
              'companySelect',
              'departmentSelect',
              'userSelect',
              'switch',
            ].includes(config.type)
          "
        >
          <el-button
            @click="handleBtnClick('changeCode', '值改变脚本')"
            type="primary"
            size="mini"
            >{{ $t("值改变脚本") }}</el-button
          >
        </div>
        <div class="mt-8" v-else-if="['btn'].includes(config.type)">
          <el-button
            @click="handleBtnClick('clickCode', '按钮点击脚本')"
            type="primary"
            size="mini"
            >{{ $t("点击脚本") }}</el-button
          >
        </div>
      </template>
    </el-form>
    <div
      v-else
      style="width: 100%; text-align: center; margin-top: 100%; color: #909399"
    >
      {{ $t("请选中组件进行设置") }}!
    </div>

    <l-dialog
      :title="$t(formTitle)"
      :visible.sync="formVisible"
      :height="528"
      :width="1000"
      @ok="handleCodeSave"
      @opened="handleOpenedForm"
    >
      <l-layout :right="400">
        <l-code-mirror
          v-model="code"
          isHint
          :handleHint="handleHint"
          mode="application/javascript"
        ></l-code-mirror>
        <template #right>
          <l-code-mirror readOnly v-model="tempCode"> </l-code-mirror>
        </template>
      </l-layout>
    </l-dialog>
  </div>
</template>

<script>
const requireComponent = require.context("./", true, /\.vue$/);
const myComponents = {};
requireComponent.keys().map((fileName) => {
  const name = fileName.split("/")[1].split(".")[0];
  if (name != "cascader") {
    myComponents[`config-${name}`] = requireComponent(fileName).default;
  }
});

export default {
  name: "component-config",
  inject: ["formDesign"],
  props: ["data"],
  components: {
    ...myComponents,
  },
  computed: {
    config() {
      return this.data;
    },
    getComponent() {
      const prefix = "config-";
      const { type } = this.config;
      return prefix + type;
    },
    dbTables() {
      if (this.config.type == "gridtable") {
        return this.formDesign.dbTables.filter((t) => t.type == "chlid");
      } else {
        return this.formDesign.dbTables;
      }
    },
    hasTableSetting() {
      return (
        !this.config.subfield &&
        !["divider", "lable", "viewtable", "card", "tab", "collapse", "btn"].includes(
          this.config.type
        )
      ); //&& this.dbTables.length > 1
    },
    isNoScript() {
      return this.formDesign.isNoScript;
    },
  },
  data() {
    return {
      formVisible: false,
      type: "",
      formTitle: "",
      code: "",
      tempCode: `
// 示例代码,只支持ES5语法
// 获取表单是新增还是编辑
var isUpdate = learun.isUpdate

// 组件id
var id = learun.prop
// 组件变更数据
var data = learun.data
// 子组件变更行号 rowIndex
var rowIndex = learun.rowIndex

// 获取主表数据
var value = learun.get('组件id')
// 获取子表行数据
var childRow = learun.get('子表组件id.行号')
// 获取子表单元格数据
var childCell = learun.get('子表组件id.行号.单元格组件id')

// 设置主表数据
learun.set({path:'组件id',value:'xxxx'})
// 添加子表行数据
var row = {}
learun.set({path:'子表组件id',value:row,type:'addTable'})
// 删除子表行数据
learun.set({path:'子表组件id.行号',type:'deleteTable'})
// 设置子表某一个单元格数据
learun.set({path:'子表组件id.行号.单元格组件id',value:'xxxxxx'})

// 获取主表字段显示值（针对下拉框等）
var label = learun.getLabel('组件id')
// 获取子表单元格显示值
var cellLabel =  learun.getLabel('子表组件id.行号.单元格组件id')

// 设置组件为必填
learun.setRequired('组件id')
// 设置组件不为必填
learun.setRequired('组件id',false)

// 设置组件为只读
learun.setDisabled('组件id')
// 取消组件为只读
learun.setDisabled('组件id',false)

// 设置组件为隐藏
learun.setHide('组件id')
// 取消组件隐藏
learun.setHide('组件id',false)

// 去掉子表某一行删除按钮
learun.set({path:'子表组件id.行号.hasNoDeleteBtn',value:false})
// 让子表某一行变成不可编辑
learun.set({path:'子表组件id.行号.disabled',value:true})
// 让子表某一行除了某一列外不可以编辑
learun.set({path:'子表组件id.行号.disabled',value:true})
learun.set({path:'子表组件id.行号.abledList',value:['编辑列组件id']})


// 显示表单加载动画
learun.loading('数据加载中')
// 关闭表单加载状态
learun.hideLoading('数据加载中')

// 提示消息
learun.message('我是力软小力')

// 获取登录者信息
var loginUser = learun.loginUser

// get请求
// 需要用到回调方法
var getCallback = function(res){ console.log(res)//请求结果 }
learun.httpGet({url:api地址,params:{},getCallback})

// post请求
// 需要用到回调方法
var postCallback = function(res){ console.log(res)//请求结果 }
learun.httpPost({url:api地址,params:{},data:{},postCallback})

// put请求
// 需要用到回调方法
var putCallback = function(res){ console.log(res)//请求结果 }
learun.httpPut({url:api地址,params:{},data:{},putCallback})

// post请求
// 需要用到回调方法
var deleteCallback = function(res){ console.log(res)//请求结果 }
learun.httpDelete({url:api地址,params:{},data:{},deleteCallback})

// 如果用来上述的请求，用了回掉方法，请最后写
return 'callback'

            `,
    };
  },
  methods: {
    notSaveChange(val){
      if(val){
        this.config.readonly = true
        this.config.display = true
      }
    },
    handleTableChange(val) {
      if (this.config.type == "gridtable" && this.config.children) {
        this.config.children.forEach((item) => {
          item.table = val;
        });
      }
    },
    getFields(tableName) {
      let table =
        this.formDesign.dbTables.find((t) => t.name == tableName) || {};
      const columns = table.columns || [];
      return columns.map((t) => ({
        ...t,
        value: t.name,
        label: t.coment ? `${t.name}(${t.coment})` : t.name,
      }));
    },
    handleFiledChange(obj) {
      if (obj) {
        this.config.csType = obj.csType;
        if (["switch"].includes(this.config.type)) {
          switch (this.config.csType) {
            case "int":
              this.config.activeValue = "1";
              this.config.inactiveValue = "0";
              this.config.valueType = "number";
              break;
            case "bool":
              this.config.activeValue = "true";
              this.config.inactiveValue = "false";
              this.config.valueType = "boolean";
              break;
            default:
              this.config.valueType = "string";
              break;
          }
        }

        if (obj.coment) {
          this.config.label = obj.coment;
        }
      } else {
        this.config.csType = "";
      }

      //console.log(obj,'字段选择')
    },

    handleBtnClick(type, title) {
      this.type = type;
      this.formTitle = title;
      this.formVisible = true;
    },
    getEvent(strEvent) {
      // 获取事件方法
      if (!this.$validatenull(strEvent)) {
        if (strEvent.indexOf("=>") != -1) {
          // 表示是老版本，提示其修改为新的版本
          console.warn("当前脚本不支持ES6语法，只支持ES5语法");
          return { res: false, msg: "脚本没有更新为最新的版本！" };
        } else {
          strEvent = `(function(){function fn(learun) {${strEvent}} return fn})()`;
          return this.$getFunction(strEvent);
        }
      } else {
        return { res: true };
      }
    },
    handleCodeSave() {
      const { res, msg } = this.getEvent(this.code);
      if (res) {
        this.data[this.type] = this.code;
        this.formVisible = false;
      } else {
        this.$message({
          type: "error",
          message: `脚本错误:${msg}`,
        });
      }
    },
    handleOpenedForm() {
      this.code = this.data[this.type] || "";
    },
    handleHint(text) {
      text = text.replace(/'/g, "");
      text = text.replace(/"/g, "");
      const list = this.formDesign.componentList.filter(
        (t) => t.label.indexOf(text) != -1 || t.prop.indexOf(text) != -1
      );
      return list.map((t) => `/*${t.label}*/"${t.prop}"`);
    },
  },
};
</script>
