<template>
  <div>
    <el-divider>{{$t('私有属性')}}</el-divider>
    <el-form-item :label="$t('默认值')">
        <l-input-icon :iconList="iconList"  v-model="data.default" >
        </l-input-icon>
    </el-form-item>     
    <el-form-item :label="$t('是否可见')">
      <el-switch v-model="data.display"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否只读')">
      <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否必填')">
      <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
    name: "config-icon",
    props: ['data'],
    data () {
        return {
        }
    },
    computed: {
        iconList(){
            if(this.lr_icons){
              return this.lr_icons
            }
            else{
              return []
            }
        }
    },
    methods: {
    }
}
</script>

