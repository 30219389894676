<template>
  <div>
    <el-form-item :label="$t('默认值')">
      <l-input-color v-model="data.default"
                      :placeholder="$t('请选择')" ></l-input-color>
    </el-form-item>
    <el-form-item :label="$t('是否只读')">
        <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否必填')">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否可见')">
      <el-switch v-model="data.display"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-color",
  props: ['data'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

