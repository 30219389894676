<template>
  <div>
    <el-form-item :label="$t('默认值')">
        <l-select  
                v-model="data.default"
                :placeholder="$t('请选择')" 
                size="mini"
                :options="myOptions"
            >
        </l-select>
    </el-form-item>
    <el-divider>{{$t('选项')}}</el-divider>
    <div style="text-align: center;margin-bottom:16px;" >
        <el-radio-group v-model="data.dataType" size="mini" @change="handleDataTypeChange" >
            <el-radio-button label="1">{{$t('静态数据')}}</el-radio-button>
            <el-radio-button label="2">{{$t('数据字典')}}</el-radio-button>
            <el-radio-button label="3">{{$t('数据源')}}</el-radio-button>
        </el-radio-group>
    </div>
    <!--静态数据-->
    <div v-if="data.dataType == 1" >
        <draggable
            :list="data.options"
            :group="{ name: 'dic' }"
            ghost-class="set-item-ghost"
            handle=".drag-item">
            <div v-for="(item, index) in data.options"
                class="set-item"
                :key="index">
            <i class="drag-item el-icon-rank"></i>
            <el-input
                style="width:49%;margin-right:2%;"
                size="mini"
                v-model="item.label"
                :placeholder="$t('选项名')"></el-input>
            <el-input
                style="width:49%;"
                size="mini"
                v-model="item.value"
                :placeholder="$t('选项值')"></el-input>
            <el-button
                @click="handleRemoveDatas(index)"
                circle
                plain
                type="danger"
                size="mini"
                icon="el-icon-minus"
                class="delete-item"
                style="padding: 4px;"></el-button>
            </div>
        </draggable>
        <div style="padding-left:22px;" >
            <el-button size="mini" type="text" icon="el-icon-circle-plus-outline" @click="handleAddDatas"  >{{$t('添加选项')}}</el-button>
        </div>
    </div>
    <!--数据字典-->
    <div v-else-if="data.dataType == 2" >
        <l-tree-select
            v-model="data.dataCode"
            :options="dataItemClassifysTree"
            :placeholder="$t('请选择')"
            size="mini"
            :parent="false"

            @change="handleDataItemChange"
        >
        </l-tree-select>
    </div>
    <!--远端数据-->
    <div v-else >
        <el-form-item :label="$t('数据源')">
            <l-select  
                    v-model="data.dataCode"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="dataSource" 
                    labelKey="f_Name" 
                    valueKey="f_Code"

                    @change="handleDataSourceChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item :label="$t('值')">
            <l-select  
                    v-model="data.dataValueKey"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="myColNameList" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item :label="$t('标签')">
            <l-select  
                    v-model="data.dataLabelKey"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="myColNameList" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item :label="$t('上一级')">
            <l-select
                v-model="data.upCtrl"
                :placeholder="$t('请选择')"
                size="mini"
                :options="componentOptions"
            >
            </l-select>
        </el-form-item>
    </div>

    <el-divider></el-divider>
    <el-form-item :label="$t('是否只读')">
        <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否必填')">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
import mixin from '../../../mixin'
export default {
    name: "config-radio",
    mixins: [mixin()],
    inject: ["formDesign"],
    props: ['data'],
    data () {
        return {
        }
    },
    created(){
        this.lr_loadDataItemClassifys && this.lr_loadDataItemClassifys()
        this.lr_loadDataSourceList && this.lr_loadDataSourceList()
        this.loadDataSourceCol()
    },
    computed:{
        dataItemClassifysTree(){
            if(this.lr_dataItemClassifysTree){
                return this.lr_dataItemClassifysTree
            }
            else{
                return []
            }
        },
        dataSource(){
            if(this.lr_dataSource){
                return this.lr_dataSource
            }
            else{
                return []
            }
        }
    },
    methods: {
        handleDataTypeChange(){
            this.data.dataCode = '';
            this.data.dataValueKey = '';
            this.data.dataLabelKey = '';
            this.data.default = '';
        },
        handleAddDatas(){
            this.data.options.push({label:'选项' + (this.data.options.length + 1) ,value:this.data.options.length + 1});
        },
        handleRemoveDatas(index){
            if(this.data.default == this.data.options[index].value){
                this.data.default = '';
            }
            this.data.options.splice(index,1);
        },

        handleDataItemChange(){
            this.data.default = ''
            if(!this.$validatenull(this.data.dataCode) && this.lr_loadDataItem){
                this.lr_loadDataItem(this.data.dataCode)
            }
        },
        handleDataSourceChange(){
            this.data.default = ''
            this.data.dataValueKey = ''
            this.data.dataLabelKey = ''
            if(!this.$validatenull(this.data.dataCode) && this.lr_loadDataSourceColNames){
                this.lr_loadDataSourceColNames(this.data.dataCode)
                this.lr_loadDataSourceData(this.data.dataCode)
            }
        },
        handleDataSourceKeyLabelChange(){
            this.data.default = ''
        }
    }
}
</script>

