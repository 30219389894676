<template>
  <div>
    <el-divider>{{$t('私有属性')}}</el-divider>
    <el-form-item :label="$t('文件类型')">
        <l-select v-model="data.accept" :options="acceptOpions" ></l-select>
    </el-form-item>
    <el-form-item :label="$t('文件大小')">
        <el-input v-model="data.maxSize" type="number" >
            <el-select v-model="data.sizeType" slot="append" :placeholder="$t('请选择')" class="s-w-72" >
                <el-option label="KB" value="KB"></el-option>
                <el-option label="MB" value="MB"></el-option>
                <el-option label="GB" value="GB"></el-option>
            </el-select>
        </el-input>
    </el-form-item>
    <el-form-item :label="$t('最大上传数')">
        <el-input-number v-model="data.limit"
                       controls-position="right"
            :placeholder="$t('请输入')"></el-input-number>
    </el-form-item>
    <el-form-item :label="$t('显示提示')">
        <el-switch v-model="data.isTip"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否只读')">
        <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
    <el-divider>{{$t('校验')}}</el-divider>
    <el-form-item :label="$t('是否必填')">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-upload",
  props: ['data'],
  data () {
    return {
        acceptOpions:[
            {value:'.xls,.xlsx',label:'Excel'},
            {value:'.doc,.docx',label:'Word'},
            {value:'.pdf',label:'Pdf'},
            {value:'.txt',label:'txt'},
            {value:'image/*',label:this.$t('图片')},
            {value:'video/*',label:this.$t('视频')},
            {value:'audio/*',label:this.$t('音频')}
        ],
        sizeTypeOpions:[]
    }
  },
  methods: {

  }
}
</script>

