<template>
  <div>
    <el-form-item :label="$t('排序字段')">
      <l-select v-model="config.orderId" @change="handleOrderChange" :options="fields" />
    </el-form-item>
    <el-form-item v-if="config.table" :label="$t('是否倒序')">
      <el-switch v-model="config.isDESC"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否必填')">
      <el-switch v-model="config.required"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('序号列')">
      <el-switch v-model="config.isShowNum"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('固定行数')">
      <el-switch v-model="config.isRowFixed"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('行数')" v-if="config.isRowFixed">
      <el-input-number
        v-model="config.rowNum"
        controls-position="right"
        :placeholder="$t('请输入')"
        :min="1"
      ></el-input-number>
    </el-form-item>
    <el-form-item v-if="!config.isRowFixed" :label="$t('添加按钮')">
      <el-switch v-model="config.isAddBtn"></el-switch>
    </el-form-item>

    <el-form-item v-if="!config.isRowFixed" :label="$t('删除按钮')">
      <el-switch v-model="config.isRemoveBtn"></el-switch>
    </el-form-item>
    <!--<el-form-item v-if="config.isAddBtn" :label="$t('按钮文本')">
      <el-input v-model="config.addBtnText"></el-input>
    </el-form-item>-->

    <el-divider>{{ $t("样式") }}</el-divider>
    <div style="text-align: center; margin-bottom: 16px">
      <el-radio-group v-model="config.classType" size="mini">
        <el-radio-button label="1">{{ $t("风格一") }}</el-radio-button>
        <el-radio-button label="2">{{ $t("风格二") }}</el-radio-button>
      </el-radio-group>
    </div>
    <el-form-item
      v-if="config.classType == '1' && config.isRowFixed"
      :label="$t('合并行')"
    >
      <el-switch v-model="config.isRowMerge"></el-switch>
    </el-form-item>
    <div
      v-if="
        config.isRowFixed &&
        config.isRowMerge &&
        config.classType == '1' &&
        config.mergeRows
      "
    >
      <div
        class="learun-row-form-input"
        :key="index"
        v-for="(item, index) in config.mergeRows"
      >
        <div class="flex">
          <l-select
            style="width: 60%"
            v-model="item.col"
            :placeholder="$t('列')"
            :options="componentOptions"
          />
          <el-input-number
            v-model="item.row1"
            controls-position="right"
            :placeholder="$t('开始行')"
            :min="1"
            :max="config.rowNum"
            size="mini"
            style="width: 40%"
          ></el-input-number>
          <el-input-number
            v-model="item.row2"
            controls-position="right"
            :placeholder="$t('结束行')"
            :min="1"
            :max="config.rowNum"
            size="mini"
            style="width: 40%"
          ></el-input-number>
        </div>
        <el-button
          :title="$t('删除')"
          @click="handleRemoveMergeRows(index)"
          class="learun-row-form-input-delete"
          circle
          plain
          size="mini"
          type="danger"
        >
          <i class="el-icon-close"></i>
        </el-button>
      </div>
    </div>
    <div class="mt-8" v-if="config.isRowFixed">
      <el-button
        v-if="config.isRowMerge && config.classType == '1'"
        type="primary"
        size="mini"
        @click="handleAddMergeRows"
        >{{ $t("添加合并设置") }}</el-button
      >
      <el-button type="primary" size="mini" @click="handleDefault">{{
        $t("设置默认值")
      }}</el-button>
    </div>

    <el-divider>{{ $t("表头合并") }}</el-divider>
    <template v-if="config.mergeHeader">
      <div
        class="reg-item"
        :key="index"
        v-for="(item, index) in config.mergeHeader"
      >
        <el-form-item
          label-width="48px"
          :label="$t('名称')"
          style="margin-bottom: 8px"
        >
          <el-input v-model="item.label" :placeholder="$t('请输入')"></el-input>
        </el-form-item>
        <el-form-item
          label-width="48px"
          :label="$t('子列')"
          style="margin-bottom: 8px"
        >
          <l-select
            v-model="item.value"
            multiple
            :placeholder="$t('请选择')"
            :options="getColumns(item.prop)"
          />
        </el-form-item>
        <el-form-item
          label-width="48px"
          :label="$t('对齐')"
          style="margin-bottom: 0"
        >
          <l-select v-model="item.align" :options="alignOptions" />
        </el-form-item>

        <el-button
          :title="$t('删除')"
          @click="handleRemoveCol(index)"
          class="reg-item-delete"
          circle
          plain
          size="mini"
          type="danger"
        >
          <i class="el-icon-close"></i>
        </el-button>
      </div>
    </template>
    <div class="mt-8">
      <el-button type="primary" size="mini" @click="handleAddCol">{{
        $t("添加")
      }}</el-button>
    </div>
    <el-divider>{{ $t("重复校验") }}</el-divider>
    <div v-if="config.vRepeatList">
      <div
        class="learun-row-form-input"
        :key="index"
        v-for="(item, index) in config.vRepeatList"
      >
        <el-form-item label-width="40px" label="字段" style="margin-bottom: 0">
          <l-select
            multiple
            :options="vRepeatOptions(index)"
            v-model="item.value"
          />
        </el-form-item>
        <el-button
          :title="$t('删除')"
          @click="handleRemoveVRepeatList(index)"
          class="learun-row-form-input-delete"
          circle
          plain
          size="mini"
          type="danger"
        >
          <i class="el-icon-close"></i>
        </el-button>
      </div>
    </div>
    <div>
      <el-button
        class="mt-8"
        type="primary"
        size="mini"
        @click="handleAddVRepeatList"
        >{{ $t("添加") }}</el-button
      >
    </div>
    <el-divider v-if="!isNoScript">{{ $t("脚本") }}</el-divider>
    <div class="mt-8" v-if="!isNoScript">
      <el-button
        @click="handleBtnClick('changeDataEvent', '行数据改变脚本')"
        type="primary"
        size="mini"
        >行数据改变脚本</el-button
      >
    </div>
    <l-dialog
      :title="formTitle"
      :visible.sync="formVisible"
      :height="528"
      :width="1000"
      @ok="handleCodeSave"
      @opened="handleOpenedForm"
    >
      <l-layout :right="400">
        <l-code-mirror
          v-model="code"
          isHint
          :handleHint="handleHint"
          mode="application/javascript"
        ></l-code-mirror>
        <template #right>
          <l-code-mirror readOnly v-model="tempCode"> </l-code-mirror>
        </template>
      </l-layout>
    </l-dialog>

    <!--默认值设置-->
    <l-dialog
      :title="$t('设置表格默认数据')"
      :height="528"
      :width="1000"
      :visible.sync="defaultFormVisible"
      @ok="handleDefaultSave"
      @opened="handleOpenedDefaultForm"
    >
      <gridtable-form ref="gridtableForm" :data="data" />
    </l-dialog>
  </div>
</template>

<script>
import GridtableForm from "./gridtableForm.vue";
export default {
  name: "config-gridtable",
  inject: ["formDesign"],
  components: {
    GridtableForm,
  },
  props: ["data"],
  data() {
    return {
      defaultFormVisible: false,

      formTitle: "",
      formVisible: false,
      code: "",
      type: "",
      alignOptions: [
        { value: "left", label: this.$t("靠左") },
        { value: "center", label: this.$t("居中") },
        { value: "right", label: this.$t("靠右") },
      ],
      tempCode: `
// 示例代码,只支持ES5语法
// 获取表单是新增还是编辑
var isUpdate = learun.isUpdate

// 组件id
var id = learun.prop
// 组件变更数据
var data = learun.data
// 子组件变更行号 rowIndex
var rowIndex = learun.rowIndex

// 获取主表数据
var value = learun.get('组件id')
// 获取子表行数据
var childRow = learun.get('子表组件id.行号')
// 获取子表单元格数据
var childCell = learun.get('子表组件id.行号.单元格组件id')

// 设置主表数据
learun.set({path:'组件id',value:'xxxx'})
// 添加子表行数据
var row = {}
learun.set({path:'子表组件id',value:row,type:'addTable'})
// 删除子表行数据
learun.set({path:'子表组件id.行号',type:'deleteTable'})
// 设置子表某一个单元格数据
learun.set({path:'子表组件id.行号.单元格组件id',value:'xxxxxx'})

// 获取主表字段显示值（针对下拉框等）
var label = learun.getLabel('组件id')
// 获取子表单元格显示值
var cellLabel =  learun.getLabel('子表组件id.行号.单元格组件id')

// 设置组件为必填
learun.setRequired('组件id')
// 设置组件不为必填
learun.setRequired('组件id',false)

// 设置组件为只读
learun.setDisabled('组件id')
// 取消组件为只读
learun.setDisabled('组件id',false)

// 设置组件为隐藏
learun.setHide('组件id')
// 取消组件隐藏
learun.setHide('组件id',false)

// 去掉子表某一行删除按钮
learun.set({path:'子表组件id.行号.hasNoDeleteBtn',value:false})
// 让子表某一行变成不可编辑
learun.set({path:'子表组件id.行号.disabled',value:true})
// 让子表某一行除了某一列外不可以编辑
learun.set({path:'子表组件id.行号.disabled',value:true})
learun.set({path:'子表组件id.行号.abledList',value:['编辑列组件id']})


// 显示表单加载动画
learun.loading('数据加载中')
// 关闭表单加载状态
learun.hideLoading('数据加载中')

// 提示消息
learun.message('我是力软小力')

// 获取登录者信息
var loginUser = learun.loginUser

// get请求
// 需要用到回调方法
var getCallback = function(res){ console.log(res)//请求结果 }
learun.httpGet({url:api地址,params:{},getCallback})

// post请求
// 需要用到回调方法
var postCallback = function(res){ console.log(res)//请求结果 }
learun.httpPost({url:api地址,params:{},data:{},postCallback})

// put请求
// 需要用到回调方法
var putCallback = function(res){ console.log(res)//请求结果 }
learun.httpPut({url:api地址,params:{},data:{},putCallback})

// post请求
// 需要用到回调方法
var deleteCallback = function(res){ console.log(res)//请求结果 }
learun.httpDelete({url:api地址,params:{},data:{},deleteCallback})

// 如果用来上述的请求，用了回掉方法，请最后写
return 'callback'

            `,
    };
  },
  computed: {
    config() {
      return this.data;
    },
    isNoScript() {
      return this.formDesign.isNoScript;
    },
    fields() {
      const table =
        this.formDesign.dbTables.find((t) => t.name == this.config.table) || {};
      return (table.columns || []).map((t) => {
        return {
          label: t.coment ? `${t.name}(${t.coment})` : t.name,
          value: t.name,
          csType:t.csType
        };
      });
    },
    componentOptions() {
      return this.config.children.map((t) => ({
        label: t.label,
        value: t.prop,
      }));
    },
    columns() {
      const res = [];

      if (this.config.mergeHeader) {
        this.config.mergeHeader.forEach((t) => {
          res.push({ label: t.label, value: t.prop });
        });
      }
      res.push(
        ...this.config.children.map((t) => ({ label: t.label, value: t.prop }))
      );

      return res;
    },
  },
  methods: {
    handleBtnClick(type, title) {
      this.type = type;
      this.formTitle = title;
      this.formVisible = true;
    },
    getEvent(strEvent) {
      // 获取事件方法
      if (!this.$validatenull(strEvent)) {
        if (strEvent.indexOf("=>") != -1) {
          // 表示是老版本，提示其修改为新的版本
          console.warn("当前脚本不支持ES6语法，只支持ES5语法");
          return { res: false, msg: "脚本没有更新为最新的版本！" };
        } else {
          strEvent = `(function(){function fn(learun) {${strEvent}} return fn})()`;
          return this.$getFunction(strEvent);
        }
      } else {
        return { res: true };
      }
    },
    handleCodeSave() {
      const { res, msg } = this.getEvent(this.code);
      if (res) {
        this.data[this.type] = this.code;
        this.formVisible = false;
      } else {
        this.$message({
          type: "error",
          message: `${this.$t("脚本错误")}:${msg}`,
        });
      }
    },
    handleOpenedForm() {
      this.code = this.data[this.type] || "";
    },
    handleHint(text) {
      text = text.replace(/'/g, "");
      text = text.replace(/"/g, "");
      const myList = this.formDesign.componentList.filter(
        (t) =>
          t.pprop == this.config.prop &&
          (t.label.indexOf(text) != -1 || t.prop.indexOf(text) != -1)
      );
      const list = this.formDesign.componentList.filter(
        (t) =>
          t.pprop != this.config.prop &&
          (t.label.indexOf(text) != -1 || t.prop.indexOf(text) != -1)
      );

      myList.push(...list);
      return myList.map((t) => `/*${t.label}*/"${t.prop}"`);
    },

    handleOrderChange(obj) {
      if (obj) {
        this.config.orderCsType = obj.csType;
      } else {
        this.config.orderCsType = "";
      }
    },

    // 重复校验字段
    vRepeatOptions(index) {
      let selectValue = "";
      if (this.config.vRepeatList) {
        this.config.vRepeatList.forEach((t, i) => {
          if (t.value && i != index) {
            selectValue += `${t.value},`;
          }
        });
      }
      return this.componentOptions.filter(
        (t) => selectValue.indexOf(t.value) == -1
      );
    },
    handleAddVRepeatList() {
      // 添加重复校验字段
      if (this.config.vRepeatList == undefined) {
        this.$set(this.config, "vRepeatList", []);
      }
      this.config.vRepeatList.push({
        value: "",
      });
    },
    handleRemoveVRepeatList(index) {
      this.config.vRepeatList.splice(index, 1);
    },

    // 表头合并设置
    getColumns(prop) {
      let selectValue = "";
      if (this.config.mergeHeader) {
        this.config.mergeHeader.forEach((t) => {
          if (t.value && t.prop != prop) {
            selectValue += `${t.value},`;
          }
        });
      }
      return this.columns.filter(
        (t) => t.value != prop && selectValue.indexOf(t.value) == -1
      );
    },
    handleAddCol() {
      if (this.config.mergeHeader == undefined) {
        this.$set(this.config, "mergeHeader", []);
      }
      this.config.mergeHeader.push({
        label: `第${this.config.mergeHeader.length}列`,
        prop: this.$uuid(),
        value: "",
        align: "left",
      });
    },
    handleRemoveCol(index) {
      this.config.mergeHeader.splice(index, 1);
    },

    // 默认值设置
    handleDefault() {
      this.defaultFormVisible = true;
    },
    handleOpenedDefaultForm() {
      const dataSource = [];
      for (let i = 0; i < this.config.rowNum; i++) {
        const row = this.config.dataSource[i];
        const point = {};
        this.config.children.forEach((col) => {
          if (row) {
            point[col.prop] = row[col.prop] || col.default || undefined;
          } else {
            point[col.prop] = col.default || undefined;
          }
        });
        dataSource.push(point);
      }
      this.$refs.gridtableForm.setData(dataSource);
    },
    handleDefaultSave() {
      this.config.dataSource = this.$refs.gridtableForm.getData();
      this.defaultFormVisible = false
    },

    // 行合并设置
    handleAddMergeRows() {
      if (this.config.mergeRows == undefined) {
        this.$set(this.config, "mergeRows", []);
      }
      this.config.mergeRows.push({
        col: "",
        row1: undefined,
        row2: undefined,
      });
    },
    handleRemoveMergeRows(index) {
      this.config.mergeRows.splice(index, 1);
    },
  },
};
</script>

