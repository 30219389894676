export default function() {
  return {
    computed: {
      dataCode() {
        return this.data.dataCode;
      },
      dataType() {
        return this.data.dataType;
      },
      myOptions() {
        // 1 静态数据 2 数据字典 3 远端数据(数据源)
        switch (this.dataType) {
          case "1":
            return this.data.options;
          case "2":
            if (this.lr_dataItem && this.dataCode) {
              this.lr_loadDataItem(this.dataCode);
              if (this.data.type == "treeselect") {
                return this.lr_dataItemTree(
                  this.lr_dataItem[this.dataCode],
                  this.data.dataValueKey,
                  this.data.dataLabelKey
                );
              } else {
                return this.lr_dataItemOptions(
                  this.lr_dataItem[this.dataCode],
                  this.data.dataValueKey,
                  this.data.dataLabelKey
                );
              }
            } else {
              return [];
            }
          case "3":
            if (
              this.lr_dataSourceData &&
              this.dataCode &&
              this.data.dataValueKey &&
              this.data.dataLabelKey
            ) {
              let dataSource = [];
              if (this.data.upCtrl) {
                if (this.formData && this.formData[this.data.upCtrl]) {
                  let upCtrlVal = this.formData[this.data.upCtrl];
                  if (upCtrlVal) upCtrlVal = upCtrlVal.toString()
                  const upCtrlValList = upCtrlVal.split(","); // 考虑数据多选的问题
                  upCtrlValList.forEach((v) => {
                    this.lr_loadDataSourceDataByParamter(this.dataCode, v); //解决编辑赋值没有触发问题
                    const vList =
                      this.lr_dataSourceDataByParamter[
                        `${this.dataCode}_${v}`
                      ] || [];
                    dataSource.push(
                      ...vList.filter(
                        (t) =>
                          !dataSource.some(
                            (t2) =>
                              t2[this.data.dataValueKey] ===
                              t[this.data.dataValueKey]
                          )
                      )
                    );
                  });
                } else if (this.data.upShowAll) {
                  this.lr_loadDataSourceDataByShowAll(this.dataCode)
                  dataSource = this.lr_dataSourceDataByShowAll[this.dataCode]
                }
              } else {
                this.lr_loadDataSourceData(this.dataCode);
                dataSource = this.lr_dataSourceData[this.dataCode];
              }

              if (this.data.type == "treeselect") {
                if (this.data.dataIdKey && this.data.dataPIdKey) {
                  return this.lr_DataSourceTree(
                    dataSource,
                    this.data.dataIdKey,
                    this.data.dataPIdKey,
                    this.data.dataValueKey,
                    this.data.dataLabelKey
                  );
                } else {
                  return [];
                }
              } else {
                return this.lr_DataSourceOptions(
                  dataSource,
                  this.data.dataValueKey,
                  this.data.dataLabelKey
                );
              }
            } else {
              return [];
            }
          default:
            return [];
        }
      },
      myColNameList() {
        if (this.lr_dataSourceCol && !this.$validatenull(this.dataCode)) {
          this.loadDataSourceCol();
          const colNameList = this.lr_dataSourceCol[this.dataCode] || [];
          return colNameList.map((t) => {
            return { value: t, label: t };
          });
        } else {
          return [];
        }
      },
      componentOptions() {
        const res = [];
        if (this.formDesign) {
          const formInfo = this.formDesign.configToSaveInfo;
          if (!this.data.subfield) {
            formInfo.tabList.forEach((tab) => {
              tab.components.forEach((com) => {
                if (
                  [
                    "checkbox",
                    "radio",
                    "select",
                    "selectMultiple",
                    "treeselect",
                    "layerselect",
                    "companySelect",
                    "departmentSelect",
                    "userSelect",
                  ].includes(com.type) &&
                  com.prop != this.data.prop
                ) {
                  res.push({ value: com.prop, label: com.label });
                }
              });
            });
          } else {
            formInfo.tabList.forEach((tab) => {
              tab.components.forEach((com) => {
                if (
                  ["gridtable"].includes(com.type) &&
                  com.children.findIndex((t) => t.prop == this.data.prop) != -1
                ) {
                  com.children.forEach((c) => {
                    if (
                      [
                        "checkbox",
                        "radio",
                        "select",
                        "selectMultiple",
                        "treeselect",
                        "layerselect",
                        "companySelect",
                        "departmentSelect",
                        "userSelect",
                      ].includes(c.type) &&
                      c.prop != this.data.prop &&
                      c.prop != this.data.prop
                    ) {
                      res.push({ value: c.prop, label: c.label });
                    }
                  });
                }
              });
            });
          }
        }
        return res;
      },
    },
    created() {
      //this.loadOptions()
    },
    methods: {
      /*loadOptions(){
              if(this.dataType == '2' && this.lr_loadDataItem && this.dataCode){
                  this.lr_loadDataItem(this.dataCode)
              }
              else if(this.dataType == '3' && this.lr_loadDataSourceData && this.dataCode){
                  if (this.data.upCtrl) {  //add by torres 20210813
                      //新写 用this.formData 获取upCtrl的值
                      if (this.formData && this.formData[this.data.upCtrl]) { //update by cbb
                          const upCtrlVal = this.formData[this.data.upCtrl]
                          this.lr_loadDataSourceDataByParamter(this.dataCode, upCtrlVal)
                      }
                  }
                  else {
                      this.lr_loadDataSourceData(this.dataCode)
                  }
              }
          },*/
      loadDataSourceCol() {
        if (
          this.dataType == "3" &&
          this.lr_loadDataSourceData &&
          !this.$validatenull(this.dataCode)
        ) {
          this.lr_loadDataSourceColNames(this.dataCode);
        }
      },
      getComponent(type) {
        let result = "el-input";
        switch (type) {
          case "divider":
            result = "el-divider";
            break;
          case "input":
          case "textarea":
          case "password":
            result = "el-input";
            break;
          case "number":
            result = "el-input-number";
            break;
          case "radio":
            result = "l-radio";
            break;
          case "checkbox":
            result = "l-checkbox";
            break;
          case "select":
          case "selectMultiple":
            result = "l-select";
            break;
          case "cascader":
            result = "el-cascader";
            break;
          case "treeselect":
            result = "l-tree-select";
            break;
          case "time":
            result = "l-time";
            break;
          case "timerange":
            result = "l-time";
            break;
          case "datetime":
            result = "l-date";
            break;
          case "datetimerange":
            result = "l-date";
            break;
          case "upload":
            result = "l-upload";
            break;
          case "uploadimg":
            result = "l-upload";
            break;
          case "guid":
            result = "l-guid";
            break;
          case "companySelect":
            result = "l-company-select";
            break;
          case "departmentSelect":
            result = "l-department-select";
            break;
          case "userSelect":
            result = "l-user-select";
            break;
          case "company":
            result = "l-company";
            break;
          case "department":
            result = "l-department";
            break;
          case "createuser":
            result = "l-createuser";
            break;
          case "modifyuser":
            result = "l-modifyuser";
            break;
          case "createtime":
            result = "l-createtime";
            break;
          case "modifytime":
            result = "l-modifytime";
            break;
          case "encode":
            result = "l-code";
            break;
          case "icon":
            result = "l-input-icon";
            break;
          case "rate":
            result = "el-rate";
            break;
          case "switch":
            result = "el-switch";
            break;
          case "slider":
            result = "el-slider";
            break;
          case "color":
            result = "l-input-color";
            break;
          case "areaselect":
            result = "l-area-select";
            break;
          case "layerselect":
            result = "l-layer-select";
            break;
          case "layerbmap":
            result = "l-BMap-select";
            break;
        }
        return result;
      },
    },
  };
}
