<template>
    <div>
        <el-divider>{{$t('私有属性')}}</el-divider>
        <el-form-item :label="$t('默认值')">
            <l-time
                v-model="data.default"
                :format="data.format"
                :selectableRange="data.selectableRange"
                :placeholder="$t('请输入')">
            </l-time>
        </el-form-item>
        <el-form-item :label="$t('时间格式')">
            <el-input v-model="data.format" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('时间范围')">
            <l-time
                is-range
                v-model="data.selectableRange"
                :start-placeholder="$t('开始时间')"
                :end-placeholder="$t('结束时间')">
            </l-time>
        </el-form-item>
        <el-form-item :label="$t('是否清空')">
            <el-switch v-model="data.clearable"></el-switch>
        </el-form-item>
        <el-form-item :label="$t('是否只读')">
            <el-switch v-model="data.readonly"></el-switch>
        </el-form-item>
        <el-divider>{{$t('校验')}}</el-divider>
        <el-form-item :label="$t('是否必填')">
            <el-switch v-model="data.required" ></el-switch>
        </el-form-item>
    </div>
</template>

<script>
export default {
  name: "config-time",
  props: ['data'],
  data () {
    return {
        formatOptions:[
            {value:'HH:mm:ss',label:'HH:mm:ss'},
            {value:'HH:mm',label:'HH:mm'}
        ]
    }
  },
  methods: {
  }
}
</script>