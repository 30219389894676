<template>
  <el-collapse  v-model="tabActive">
    <el-collapse-item
      v-for="(item, index) in  data.tabList"
      :key="index"
      :title="item.text"
      :name="index + ''"
    >
      <l-form-wraper :myComponents="item.children" />
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: "collapse-wraper",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    tabActive: {
      get() {
        return this.data.active;
      },
      set(val) {
        this.data.active = val;
      },
    },
  },
  methods: {},
};
</script>