<template>
  <div>
    <el-divider>{{ $t("折叠卡") }}</el-divider>
    <draggable
      :list="tabList"
      :group="{ name: 'dic' }"
      ghost-class="set-item-ghost"
      handle=".drag-item"
    >
      <div v-for="(item, index) in tabList" class="set-item" :key="index">
        <i class="drag-item el-icon-rank"></i>
        <el-input
          size="mini"
          v-model="item.text"
          :placeholder="$t('折叠卡名称')"
        ></el-input>
        <el-button
          v-if="tabList.length > 1"
          @click="handleRemoveTabs(index)"
          circle
          plain
          type="danger"
          size="mini"
          icon="el-icon-minus"
          class="delete-item"
          style="padding: 4px"
        ></el-button>
      </div>
    </draggable>
    <div style="padding-left: 22px">
      <el-button
        size="mini"
        type="text"
        icon="el-icon-circle-plus-outline"
        @click="handleAddTabs"
        >{{ $t("添加折叠卡") }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "config-collapse",
  inject: ["formDesign"],
  props: ["data"],
  data() {
    return {};
  },
  computed: {
    tabList() {
      return this.data.tabList;
    },
  },
  methods: {
    handleAddTabs() {
      this.tabList.push({
        children: [],
        text: this.$t("折叠卡") + this.tabList.length,
      });
    },
    handleRemoveTabs(index) {
      if (index + "" == this.data.active) {
        this.data.active = "0";
      }
      this.tabList.splice(index, 1);
    },
  },
};
</script>

