<template>
  <div class="form-config-container">
    <el-form label-position="left" label-width="80px" size="mini">
      <el-form-item :label="$t('表单尺寸')">
        <el-radio-group v-model="formInfo.size">
          <el-radio-button label="medium">{{ $t("中等") }}</el-radio-button>
          <el-radio-button label="small">{{ $t("较小") }}</el-radio-button>
          <el-radio-button label="mini">{{ $t("迷你") }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('标签对齐')">
        <el-radio-group v-model="formInfo.labelPosition">
          <el-radio-button label="right">{{ $t("右") }}</el-radio-button>
          <el-radio-button label="left">{{ $t("左") }}</el-radio-button>
          <el-radio-button label="top">{{ $t("顶部") }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('标题宽度')">
        <el-input-number
          v-model="formInfo.labelWidth"
          controls-position="right"
          :placeholder="$t('请输入')"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('栅格间隔')">
        <el-input-number
          v-model="formInfo.gutter"
          controls-position="right"
          :placeholder="$t('请输入')"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('打开方式')">
        <l-select
          v-model="formInfo.openType"
          :options="[
            { value: '1', label: $t('弹窗') },
            { value: '2', label: $t('抽屉') },
          ]"
        ></l-select>
      </el-form-item>
      <el-form-item :label="$t('弹窗宽度')" v-if="formInfo.openType == '1'">
        <el-input-number
          v-model="formInfo.dialogWidth"
          controls-position="right"
          :placeholder="$t('请输入')"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('弹窗高度')" v-if="formInfo.openType == '1'">
        <el-input-number
          v-model="formInfo.dialogHeight"
          controls-position="right"
          :placeholder="$t('请输入')"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('抽屉宽度')" v-if="formInfo.openType == '2'">
        <el-input-number
          v-model="formInfo.drawerWidth"
          controls-position="right"
          :placeholder="$t('请输入')"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('数据历史')">
        <el-radio-group v-model="formInfo.historyType">
          <el-radio-button label="0">{{ $t("无") }}</el-radio-button>
          <el-radio-button label="1">{{ $t("公用") }}</el-radio-button>
          <el-radio-button label="2">{{ $t("私有") }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-divider>{{ $t("重复校验") }}</el-divider>
      <div v-if="formInfo.vRepeatList">
        <div
          class="learun-row-form-input"
          :key="index"
          v-for="(item, index) in formInfo.vRepeatList"
        >
          <el-form-item
            label-width="40px"
            label="字段"
            style="margin-bottom: 0"
          >
            <l-select
              multiple
              :options="vRepeatOptions(index)"
              v-model="item.value"
            />
          </el-form-item>
          <el-button
            :title="$t('删除')"
            @click="handleRemoveVRepeatList(index)"
            class="learun-row-form-input-delete"
            circle
            plain
            size="mini"
            type="danger"
          >
            <i class="el-icon-close"></i>
          </el-button>
        </div>
      </div>
      <div>
        <el-button
          class="mt-8"
          type="primary"
          size="mini"
          @click="handleAddVRepeatList"
          >{{ $t("添加") }}</el-button
        >
      </div>
      <el-divider>{{ $t("选项卡") }}</el-divider>
      <draggable
        :list="tabList"
        :group="{ name: 'dic' }"
        ghost-class="set-item-ghost"
        handle=".drag-item"
      >
        <div v-for="(item, index) in tabList" class="set-item" :key="index">
          <i class="drag-item el-icon-rank"></i>
          <el-input
            size="mini"
            v-model="item.text"
            :placeholder="$t('选项卡名称')"
          ></el-input>
          <el-button
            v-if="tabList.length > 1"
            @click="handleRemoveTabs(index)"
            circle
            plain
            type="danger"
            size="mini"
            icon="el-icon-minus"
            class="delete-item"
            style="padding: 4px"
          ></el-button>
        </div>
      </draggable>
      <div style="padding-left: 22px">
        <el-button
          size="mini"
          type="text"
          icon="el-icon-circle-plus-outline"
          @click="handleAddTabs"
          >{{ $t("添加选项卡") }}</el-button
        >
      </div>

      <template v-if="!isNoScript">
        <el-divider>{{ $t("脚本") }}</el-divider>
        <div class="mt-8">
          <el-button
            @click="handleBtnClick('beforeSetData', '添加赋值前脚本')"
            type="primary"
            size="mini"
            >添加赋值前脚本</el-button
          >
          <el-button
            @click="handleBtnClick('afterValidateForm', '添加校验后脚本')"
            type="primary"
            size="mini"
            >添加校验后脚本</el-button
          >
        </div>
        <div class="mt-8">
          <el-button
            @click="handleBtnClick('afterSaveEvent', '添加保存后脚本')"
            type="primary"
            size="mini"
            >添加保存后脚本</el-button
          >
          <el-button
            @click="handleBtnClick('changeDataEvent', '添加数据改变脚本')"
            type="primary"
            size="mini"
            >添加数据改变脚本</el-button
          >
        </div>
      </template>
    </el-form>
    <l-dialog
      :title="formTitle"
      :visible.sync="formVisible"
      :height="528"
      :width="1000"
      @ok="handleCodeSave"
      @opened="handleOpenedForm"
    >
      <l-layout :right="400">
        <l-code-mirror
          v-model="code"
          isHint
          :handleHint="handleHint"
          mode="application/javascript"
        ></l-code-mirror>
        <template #right>
          <l-code-mirror readOnly v-model="tempCode"> </l-code-mirror>
        </template>
      </l-layout>
    </l-dialog>
  </div>
</template>

<script>
export default {
  name: "form-config",
  components: {},
  inject: ["formDesign"],
  data() {
    return {
      formTitle: "",
      formVisible: false,
      code: "",
      type: "",
      tempCode: `
// 示例代码,只支持ES5语法
// 获取表单是新增还是编辑
var isUpdate = learun.isUpdate

// 组件id
var id = learun.prop
// 组件变更数据
var data = learun.data
// 子组件变更行号 rowIndex
var rowIndex = learun.rowIndex

// 获取主表数据
var value = learun.get('组件id')
// 获取子表行数据
var childRow = learun.get('子表组件id.行号')
// 获取子表单元格数据
var childCell = learun.get('子表组件id.行号.单元格组件id')

// 设置主表数据
learun.set({path:'组件id',value:'xxxx'})
// 添加子表行数据
var row = {}
learun.set({path:'子表组件id',value:row,type:'addTable'})
// 删除子表行数据
learun.set({path:'子表组件id.行号',type:'deleteTable'})
// 设置子表某一个单元格数据
learun.set({path:'子表组件id.行号.单元格组件id',value:'xxxxxx'})

// 获取主表字段显示值（针对下拉框等）
var label = learun.getLabel('组件id')
// 获取子表单元格显示值
var cellLabel =  learun.getLabel('子表组件id.行号.单元格组件id')

// 设置组件为必填
learun.setRequired('组件id')
// 设置组件不为必填
learun.setRequired('组件id',false)

// 设置组件为只读
learun.setDisabled('组件id')
// 取消组件为只读
learun.setDisabled('组件id',false)

// 设置组件为隐藏
learun.setHide('组件id')
// 取消组件隐藏
learun.setHide('组件id',false)

// 去掉子表某一行删除按钮
learun.set({path:'子表组件id.行号.hasNoDeleteBtn',value:false})
// 让子表某一行变成不可编辑
learun.set({path:'子表组件id.行号.disabled',value:true})
// 让子表某一行除了某一列外不可以编辑
learun.set({path:'子表组件id.行号.disabled',value:true})
learun.set({path:'子表组件id.行号.abledList',value:['编辑列组件id']})


// 显示表单加载动画
learun.loading('数据加载中')
// 关闭表单加载状态
learun.hideLoading('数据加载中')

// 提示消息
learun.message('我是力软小力')

// 获取登录者信息
var loginUser = learun.loginUser

// get请求
// 需要用到回调方法
var getCallback = function(res){ console.log(res)//请求结果 }
learun.httpGet({url:api地址,params:{},getCallback})

// post请求
// 需要用到回调方法
var postCallback = function(res){ console.log(res)//请求结果 }
learun.httpPost({url:api地址,params:{},data:{},postCallback})

// put请求
// 需要用到回调方法
var putCallback = function(res){ console.log(res)//请求结果 }
learun.httpPut({url:api地址,params:{},data:{},putCallback})

// post请求
// 需要用到回调方法
var deleteCallback = function(res){ console.log(res)//请求结果 }
learun.httpDelete({url:api地址,params:{},data:{},deleteCallback})

// 如果用来上述的请求，用了回掉方法，请最后写
return 'callback'

            `,
    };
  },
  computed: {
    tabList() {
      return this.formDesign.formInfo.tabList;
    },
    formInfo() {
      return this.formDesign.formInfo;
    },
    isNoScript() {
      return this.formDesign.isNoScript;
    },
    componentOptions() {
      const res = [];
      if (this.formDesign) {
        const formInfo = this.formDesign.configToSaveInfo;
        formInfo.tabList.forEach((tab) => {
          tab.components.forEach((com) => {
            if (
              ![
                "viewtable",
                "card",
                "btn",
                "tab",
                "collapse",
                "divider",
                "lable",
                "gridtable",
                "upload",
                "uploadimg",
              ].includes(com.type) &&
              !com.isNotSave &&
              com.table
            ) {
              res.push({ value: com.prop, label: com.label, table: com.table });
            }
          });
        });
      }
      return res;
    },
  },
  methods: {
    handleAddTabs() {
      this.tabList.push({
        components: [],
        text: this.$t("新建选项卡") + this.tabList.length,
      });
    },
    handleRemoveTabs(index) {
      if (`tab${index}` == this.formDesign.formActiveName) {
        this.formDesign.formActiveName = "tab0";
      }
      this.tabList.splice(index, 1);
    },

    handleBtnClick(type, title) {
      this.type = type;
      this.formTitle = title;
      this.formVisible = true;
    },

    getEvent(strEvent) {
      // 获取事件方法
      if (!this.$validatenull(strEvent)) {
        if (strEvent.indexOf("=>") != -1) {
          // 表示是老版本，提示其修改为新的版本
          console.warn("当前脚本不支持ES6语法，只支持ES5语法");
          return { res: false, msg: "脚本没有更新为最新的版本！" };
        } else {
          strEvent = `(function(){function fn(learun) {${strEvent}} return fn})()`;
          return this.$getFunction(strEvent);
        }
      } else {
        return { res: true };
      }
    },

    handleCodeSave() {
      const { res, msg } = this.getEvent(this.code);
      if (res) {
        this.formInfo[this.type] = this.code;
        this.formVisible = false;
      } else {
        this.$message({
          type: "error",
          message: `${this.$t("脚本错误")}:${msg}`,
        });
      }
    },
    handleOpenedForm() {
      this.code = this.formInfo[this.type];
    },
    handleHint(text) {
      text = text.replace(/'/g, "");
      text = text.replace(/"/g, "");
      const list = this.formDesign.componentList.filter(
        (t) => t.label.indexOf(text) != -1 || t.prop.indexOf(text) != -1
      );
      return list.map((t) => `/*${t.label}*/"${t.prop}"`);
    },

    handleAddVRepeatList() {
      // 添加重复校验字段
      if (this.formInfo.vRepeatList == undefined) {
        this.$set(this.formInfo, "vRepeatList", []);
      }
      this.formInfo.vRepeatList.push({
        value: "",
      });
    },
    handleRemoveVRepeatList(index) {
      this.formInfo.vRepeatList.splice(index, 1);
    },
    vRepeatOptions(index) {
      let selectValue = "";
      let table = "";
      if (this.formInfo.vRepeatList) {
        this.formInfo.vRepeatList.forEach((t, i) => {
          if (t.value && i != index) {
            selectValue += `${t.value},`;
          } else if (t.value && i == index) {
            const vItem = this.componentOptions.find(
              (t2) => t2.value == t.value.split(",")[0]
            );
            if (vItem) {
              table = vItem.table;
            }
          }
        });
      }
      return this.componentOptions.filter(
        (t) =>
          selectValue.indexOf(t.value) == -1 && (!table || table == t.table)
      );
    },
  },
};
</script>

