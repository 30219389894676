var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.myComponents),function(col){return [(['gridtable'].includes(col.type))?_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(col.display != false),expression:"col.display != false"}],key:col.prop,staticStyle:{"margin-bottom":"16px"},attrs:{"span":24}},[(!_vm.$validatenull(col.label))?_c('div',{staticClass:"l-title"},[_vm._v(" "+_vm._s(_vm.$t(col.label))+" ")]):_vm._e(),(col.display != false)?_c('l-edit-table',{ref:col.prop,refInFor:true,attrs:{"required":col.required,"addBtnText":col.addBtnText,"isAddBtn":col.isAddBtn && !col.isRowFixed,"isRemoveBtn":col.isRemoveBtn && !col.isRowFixed,"isShowNum":col.isShowNum,"columns":_vm.getTableColumns(col.children, col.mergeHeader),"dataSource":_vm.formData[col.prop],"filterDeleteBtn":_vm.filterTableDeleteBtn,"isRead":_vm.isRead,"classType":col.classType == '2' ? 'element' : 'adms',"columnsType":"array","merges":_vm.merges(col)},on:{"addRow":function($event){return _vm.handleAddRow(col)},"deleteRow":function($event){return _vm.handleDeleteRow($event, col)}},scopedSlots:_vm._u([_vm._l((col.children),function(column){return {key:_vm.getSlotName(column.prop,column),fn:function(scope){return [_c('form-item',{key:column.prop,attrs:{"isRead":_vm.isRead,"upCtrls":_vm.upCtrls,"formData":scope.row,"data":column},on:{"change":function($event){return _vm.handleItemChange($event, col, scope.$index, scope.row)},"btnClick":function($event){return _vm.componentBtnClick(
                $event.component,
                col,
                scope.$index,
                scope.row
              )}},model:{value:(scope.row[column.prop]),callback:function ($$v) {_vm.$set(scope.row, column.prop, $$v)},expression:"scope.row[column.prop]"}})]}}})],null,true)}):_vm._e()],1):(['viewtable'].includes(col.type))?_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(col.display != false),expression:"col.display != false"}],key:col.prop,staticStyle:{"margin-bottom":"16px"},attrs:{"span":24}},[(!_vm.$validatenull(col.label))?_c('div',{staticClass:"l-title"},[_vm._v(" "+_vm._s(_vm.$t(col.label))+" ")]):_vm._e(),_c('l-view-table',{attrs:{"paramFiled":_vm.formData[col.paramFiled],"columns":col.columns,"code":col.dataCode}})],1):(col.type == 'tab')?_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(col.display != false),expression:"col.display != false"}],key:col.prop,staticStyle:{"margin-bottom":"16px"},attrs:{"span":col.span}},[_c('tab-wraper',{attrs:{"data":col}})],1):(col.type == 'collapse')?_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(col.display != false),expression:"col.display != false"}],key:col.prop,staticStyle:{"margin-bottom":"16px"},attrs:{"span":col.span}},[_c('collapse-wraper',{attrs:{"data":col}})],1):_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(col.display != false),expression:"col.display != false"}],key:col.prop,attrs:{"span":col.span}},[(col.type == 'card')?_c('card-wraper',{attrs:{"data":col}}):(col.type == 'divider')?_c('form-item',{attrs:{"data":col}}):(col.type == 'lable')?_c('el-form-item',{staticClass:"el-form-item-box",style:({ 'font-size': ((col.fontSize) + "px") }),attrs:{"labelWidth":"0px"}},[_c('div',{style:({
            'text-align': col.contentPosition,
            'font-size': ((col.fontSize) + "px"),
            color: col.color,
            'font-weight': 'bold',
          })},[_vm._v(" "+_vm._s(_vm.$t(col.label))+" ")])]):(col.type == 'btn')?_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(col.display && !_vm.isRead),expression:"col.display && !isRead"}],attrs:{"labelWidth":"0px"}},[_c('el-button',{attrs:{"size":col.size,"plain":col.plain,"round":col.round,"circle":col.circle,"icon":col.myIcon,"type":col.myType,"disabled":col.disabled},on:{"click":function($event){return _vm.componentBtnClick(col)}}},[_vm._v(_vm._s(col.label))])],1):_c('el-form-item',{attrs:{"label":col.label,"labelWidth":col.labelWidth == undefined ? undefined : col.labelWidth + 'px',"prop":col.prop}},[_c('form-item',{attrs:{"isRead":_vm.isRead,"isPreview":_vm.isPreview,"isForm":true,"upCtrls":_vm.upCtrls,"data":col,"formData":_vm.formData},on:{"change":_vm.handleItemChange},model:{value:(_vm.formData[col.prop]),callback:function ($$v) {_vm.$set(_vm.formData, col.prop, $$v)},expression:"formData[col.prop]"}})],1)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }