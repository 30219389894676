<template>
  <div class="drag-form-container l-tabs-container">
    <el-form
      :label-position="formInfo.labelPosition || 'left'"
      :label-width="formInfo.labelWidth ? `${formInfo.labelWidth}px` : '80px'"
      :size="formInfo.size || 'mini'"
      :model="form"
    >
      <input type="text" style="display: none" />
      <drag-form-wraper
        ref="dragFormWraper"
        :select.sync="selectWidget"
        :componentsList="formComponents"
       />
    </el-form>
  </div>
</template>
<script>
import DragFormWraper from "./dragFormWraper";
export default {
  name: "drag-form",
  inject: ["formDesign"],
  components:{
    DragFormWraper
  },
  props: {
    select: Object,
    myTabsIndex: Number
  },
  computed: {
    formInfo() {
      return this.formDesign.formInfo;
    },
    formComponents() {
      return (
        this.formDesign.formInfo.tabList[this.myTabsIndex].components || []
      );
    },
    selectWidget: {
      get() {
        return this.select;
      },
      set(val) {
        this.$emit("update:select", val);
      },
    },
    dbTables() {
      return this.formDesign.dbTables;
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    handleFormClickAdd(item) {
      this.$refs.dragFormWraper.handleFormClickAdd(item)
    }
  },
};
</script>
