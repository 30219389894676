var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-config"},[(this.config && Object.keys(this.config).length > 0)?_c('el-form',{attrs:{"label-width":"88px","label-position":"left","size":"mini"}},[_c('el-form-item',{attrs:{"label":"ID"}},[_c('el-input',{attrs:{"readonly":"readonly"},model:{value:(_vm.config.prop),callback:function ($$v) {_vm.$set(_vm.config, "prop", $$v)},expression:"config.prop"}})],1),(['input'].includes(_vm.config.type))?_c('el-form-item',{attrs:{"label":_vm.$t('不存表')}},[_c('el-switch',{on:{"change":_vm.notSaveChange},model:{value:(_vm.config.isNotSave),callback:function ($$v) {_vm.$set(_vm.config, "isNotSave", $$v)},expression:"config.isNotSave"}})],1):_vm._e(),(!_vm.config.isNotSave && _vm.hasTableSetting)?_c('el-form-item',{attrs:{"label":_vm.$t('数据表')}},[_c('el-select',{attrs:{"placeholder":_vm.$t('请选择')},on:{"change":_vm.handleTableChange},model:{value:(_vm.config.table),callback:function ($$v) {_vm.$set(_vm.config, "table", $$v)},expression:"config.table"}},_vm._l((_vm.dbTables),function(item){return _c('el-option',{key:item.name,attrs:{"label":item.name,"value":item.name}})}),1)],1):_vm._e(),(
        ![
          'gridtable',
          'divider',
          'lable',
          'viewtable',
          'card',
          'collapse',
          'tab',
          'btn' ].includes(_vm.config.type) && !_vm.config.isNotSave
      )?_c('el-form-item',{attrs:{"label":_vm.$t('字段名')}},[_c('l-select',{attrs:{"options":_vm.getFields(_vm.config.table)},on:{"change":_vm.handleFiledChange},model:{value:(_vm.config.field),callback:function ($$v) {_vm.$set(_vm.config, "field", $$v)},expression:"config.field"}})],1):_vm._e(),(!['divider', 'lable', 'tab', 'collapse'].includes(_vm.config.type))?_c('el-form-item',{attrs:{"label":_vm.config.type == 'btn' ? _vm.$t('按钮名称') : _vm.$t('标题名')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('请输入')},model:{value:(_vm.config.label),callback:function ($$v) {_vm.$set(_vm.config, "label", $$v)},expression:"config.label"}})],1):_vm._e(),(
        ![
          'gridtable',
          'divider',
          'lable',
          'viewtable',
          'card',
          'collapse',
          'tab',
          'btn' ].includes(_vm.config.type)
      )?_c('el-form-item',{attrs:{"label":_vm.config.subfield ? _vm.$t('单元格宽度') : _vm.$t('标题宽度')}},[_c('el-input-number',{attrs:{"controls-position":"right","placeholder":_vm.$t('请输入'),"min":0},model:{value:(_vm.config.labelWidth),callback:function ($$v) {_vm.$set(_vm.config, "labelWidth", $$v)},expression:"config.labelWidth"}})],1):_vm._e(),(
        [
          'btn' ].includes(_vm.config.type) && _vm.config.subfield
      )?_c('el-form-item',{attrs:{"label":_vm.config.subfield ? _vm.$t('单元格宽度') : ''}},[_c('el-input-number',{attrs:{"controls-position":"right","placeholder":_vm.$t('请输入'),"min":0},model:{value:(_vm.config.labelWidth),callback:function ($$v) {_vm.$set(_vm.config, "labelWidth", $$v)},expression:"config.labelWidth"}})],1):_vm._e(),(
        ![
          'btn',
          'card',
          'collapse',
          'tab',
          'gridtable',
          'divider',
          'lable',
          'radio',
          'timerange',
          'datetimerange',
          'upload',
          'uploadimg',
          'guid',
          'rate',
          'company',
          'department',
          'modifytime',
          'modifyuser',
          'createtime',
          'createuser',
          'viewtable' ].includes(_vm.config.type)
      )?_c('el-form-item',{attrs:{"label":_vm.$t('占位提示')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('请输入')},model:{value:(_vm.config.placeholder),callback:function ($$v) {_vm.$set(_vm.config, "placeholder", $$v)},expression:"config.placeholder"}})],1):_vm._e(),(['timerange', 'datetimerange'].includes(_vm.config.type))?_c('el-form-item',{attrs:{"label":_vm.$t('开始占位')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('请输入')},model:{value:(_vm.config.startPlaceholder),callback:function ($$v) {_vm.$set(_vm.config, "startPlaceholder", $$v)},expression:"config.startPlaceholder"}})],1):_vm._e(),(['timerange', 'datetimerange'].includes(_vm.config.type))?_c('el-form-item',{attrs:{"label":_vm.$t('结束占位')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('请输入')},model:{value:(_vm.config.endPlaceholder),callback:function ($$v) {_vm.$set(_vm.config, "endPlaceholder", $$v)},expression:"config.endPlaceholder"}})],1):_vm._e(),(
        !_vm.config.subfield &&
        !['gridtable', 'texteditor', 'viewtable'].includes(_vm.config.type)
      )?_c('el-form-item',{attrs:{"label":_vm.$t('表单栅格')}},[_c('el-slider',{attrs:{"step":6,"min":6,"max":24,"show-stops":""},model:{value:(_vm.config.span),callback:function ($$v) {_vm.$set(_vm.config, "span", $$v)},expression:"config.span"}})],1):_vm._e(),_c(_vm.getComponent,{tag:"component",attrs:{"data":_vm.config}}),(!_vm.config.subfield && !_vm.isNoScript || _vm.config.type == 'btn')?[(
          [
            'btn',
            'radio',
            'checkbox',
            'select',
            'selectMultiple',
            'treeselect',
            'layerselect',
            'areaselect',
            'companySelect',
            'departmentSelect',
            'userSelect',
            'switch' ].includes(_vm.config.type)
        )?_c('el-divider',[_vm._v(" "+_vm._s(_vm.$t("脚本"))+" ")]):_vm._e(),(
          [
            'radio',
            'checkbox',
            'select',
            'selectMultiple',
            'treeselect',
            'layerselect',
            'areaselect',
            'companySelect',
            'departmentSelect',
            'userSelect',
            'switch' ].includes(_vm.config.type)
        )?_c('div',{staticClass:"mt-8"},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.handleBtnClick('changeCode', '值改变脚本')}}},[_vm._v(_vm._s(_vm.$t("值改变脚本")))])],1):(['btn'].includes(_vm.config.type))?_c('div',{staticClass:"mt-8"},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.handleBtnClick('clickCode', '按钮点击脚本')}}},[_vm._v(_vm._s(_vm.$t("点击脚本")))])],1):_vm._e()]:_vm._e()],2):_c('div',{staticStyle:{"width":"100%","text-align":"center","margin-top":"100%","color":"#909399"}},[_vm._v(" "+_vm._s(_vm.$t("请选中组件进行设置"))+"! ")]),_c('l-dialog',{attrs:{"title":_vm.$t(_vm.formTitle),"visible":_vm.formVisible,"height":528,"width":1000},on:{"update:visible":function($event){_vm.formVisible=$event},"ok":_vm.handleCodeSave,"opened":_vm.handleOpenedForm}},[_c('l-layout',{attrs:{"right":400},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('l-code-mirror',{attrs:{"readOnly":""},model:{value:(_vm.tempCode),callback:function ($$v) {_vm.tempCode=$$v},expression:"tempCode"}})]},proxy:true}])},[_c('l-code-mirror',{attrs:{"isHint":"","handleHint":_vm.handleHint,"mode":"application/javascript"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }