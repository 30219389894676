<template>
  <div>
    <template v-for="col in myComponents">
      <el-col
        style="margin-bottom: 16px"
        v-if="['gridtable'].includes(col.type)"
        :key="col.prop"
        :span="24"
        v-show="col.display != false"
      >
        <div v-if="!$validatenull(col.label)" class="l-title">
          {{ $t(col.label) }}
        </div>
        <l-edit-table
          v-if="col.display != false"
          :required="col.required"
          :addBtnText="col.addBtnText"
          :isAddBtn="col.isAddBtn && !col.isRowFixed"
          :isRemoveBtn="col.isRemoveBtn && !col.isRowFixed"
          :isShowNum="col.isShowNum"
          :columns="getTableColumns(col.children, col.mergeHeader)"
          :dataSource="formData[col.prop]"
          :filterDeleteBtn="filterTableDeleteBtn"
          :isRead="isRead"
          :classType="col.classType == '2' ? 'element' : 'adms'"
          columnsType="array"
          @addRow="handleAddRow(col)"
          @deleteRow="handleDeleteRow($event, col)"
          :merges="merges(col)"
          :ref="col.prop"
        >
          <template
            v-for="column in col.children"
            v-slot:[getSlotName(column.prop,column)]="scope"
          >
            <form-item
              :key="column.prop"
              :isRead="isRead"
              v-model="scope.row[column.prop]"
              :upCtrls="upCtrls"
              :formData="scope.row"
              :data="column"
              @change="handleItemChange($event, col, scope.$index, scope.row)"
              @btnClick="
                componentBtnClick(
                  $event.component,
                  col,
                  scope.$index,
                  scope.row
                )
              "
            />
          </template>
        </l-edit-table>
      </el-col>
      <el-col
        style="margin-bottom: 16px"
        v-else-if="['viewtable'].includes(col.type)"
        :key="col.prop"
        :span="24"
        v-show="col.display != false"
      >
        <div v-if="!$validatenull(col.label)" class="l-title">
          {{ $t(col.label) }}
        </div>
        <l-view-table
          :paramFiled="formData[col.paramFiled]"
          :columns="col.columns"
          :code="col.dataCode"
        >
        </l-view-table>
      </el-col>
      <el-col
        v-else-if="col.type == 'tab'"
        :key="col.prop"
        :span="col.span"
        style="margin-bottom: 16px"
        v-show="col.display != false"
      >
        <tab-wraper :data="col" />
      </el-col>
      <el-col
        v-else-if="col.type == 'collapse'"
        :key="col.prop"
        :span="col.span"
        style="margin-bottom: 16px"
        v-show="col.display != false"
      >
        <collapse-wraper :data="col" />
      </el-col>

      <el-col
        v-else
        v-show="col.display != false"
        :key="col.prop"
        :span="col.span"
      >
        <card-wraper v-if="col.type == 'card'" :data="col" />
        <form-item v-else-if="col.type == 'divider'" :data="col" />
        <el-form-item
          v-else-if="col.type == 'lable'"
          class="el-form-item-box"
          labelWidth="0px"
          :style="{ 'font-size': `${col.fontSize}px` }"
        >
          <div
            :style="{
              'text-align': col.contentPosition,
              'font-size': `${col.fontSize}px`,
              color: col.color,
              'font-weight': 'bold',
            }"
          >
            {{ $t(col.label) }}
          </div>
        </el-form-item>
        <el-form-item
          v-else-if="col.type == 'btn'"
          v-show="col.display && !isRead"
          labelWidth="0px"
        >
          <el-button
            :size="col.size"
            :plain="col.plain"
            :round="col.round"
            :circle="col.circle"
            :icon="col.myIcon"
            :type="col.myType"
            :disabled="col.disabled"
            @click="componentBtnClick(col)"
            >{{ col.label }}</el-button
          >
        </el-form-item>
        <el-form-item
          v-else
          :label="col.label"
          :labelWidth="
            col.labelWidth == undefined ? undefined : col.labelWidth + 'px'
          "
          :prop="col.prop"
        >
          <form-item
            v-model="formData[col.prop]"
            :isRead="isRead"
            :isPreview="isPreview"
            :isForm="true"
            :upCtrls="upCtrls"
            :data="col"
            :formData="formData"
            @change="handleItemChange"
          >
          </form-item>
        </el-form-item>
      </el-col>
    </template>
  </div>
</template>

<script>
import CardWraper from "./cardWraper.vue";
import TabWraper from "./tabWraper.vue";
import CollapseWraper from "./collapseWraper.vue";
export default {
  name: "l-form-wraper",
  inject: ["formViewer"],
  components: {
    CardWraper,
    TabWraper,
    CollapseWraper,
  },
  props: {
    myComponents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    isRead() {
      return this.formViewer.isRead;
    },
    isPreview() {
      return this.formViewer.isPreview;
    },
    upCtrls() {
      return this.formViewer.upCtrls;
    },
    formData() {
      return this.formViewer.formData;
    },
  },
  mounted() {
    for (let key in this.$refs) {
      this.formViewer.gridtableRefs[key] = this.$refs[key];
    }
  },
  methods: {
    handleItemChange(data, tableComponent, tableIndex, tableRow) {
      if (
        tableComponent &&
        tableComponent.isRowFixed &&
        tableComponent.isRowMerge &&
        tableComponent.classType == "1" &&
        tableComponent.mergeRows
      ) {
        // 如果设置了行合并，需要给合并的行数据设置值
        const row = tableComponent.mergeRows.find(
          (t) => t.col == data.prop && t.row1 == tableIndex + 1
        );
        if (row) {
          for (let i = row.row1; i < row.row2; i++) {
            this.formData[tableComponent.prop][i][data.prop] =
              tableRow[data.prop];
          }
        }
      }

      return this.formViewer.handleItemChange(
        data,
        tableComponent,
        tableIndex,
        tableRow
      );
    },
    componentBtnClick(col, table, index, row) {
      return this.formViewer.componentBtnClick(col, table, index, row);
    },

    // 编辑表格操作方法
    getTableColumns(children, mergeHeader) {
      const columns = [];

      const colMap = {};
      const headerMap = {};
      if (mergeHeader) {
        mergeHeader.forEach((t) => {
          if (t.value) {
            const values = t.value.split(",");
            values.forEach((v) => {
              colMap[v] = t.prop;
            });
            headerMap[t.prop] = t;
          }
        });
      }
      let _headerMap = this.$deepClone(headerMap);
      children.forEach((col) => {
        this.setTreeColumns(
          colMap[col.prop] || "0",
          headerMap,
          colMap,
          columns,
          _headerMap
        );

        columns.push(
          this.setComponentDataType(
            {
              _id: col.prop,
              _pid: colMap[col.prop] || "0",
              id: col.prop,
              isHidden: !col.display,
              prop: col.prop,
              label: col.label,
              width: col.labelWidth,
              required: col.required,
              patterns: col.patterns,
            },
            col
          )
        );
      });
      _headerMap = null;
      return columns;
    },
    // 获取组件数据类型
    setComponentDataType(col, compt) {
      if (
        [
          "radio",
          "checkbox",
          "select",
          "selectMultiple",
          "treeselect",
          "layerselect",
        ].includes(compt.type)
      ) {
        switch (compt.dataType) {
          case "1":
            col.dataType = "mydata";
            col.options = compt.options;
            break;
          case "2":
            col.dataType = "dataItem";
            col.dataCode = compt.dataCode;
            break;
          case "3":
            col.dataType = "dataSource";
            col.dataCode = compt.dataCode;
            col.valueKey = compt.dataValueKey;
            col.labelKey = compt.dataLabelKey;
            break;
        }
      } else if (
        ["datetime", "createtime", "modifytime"].includes(compt.type)
      ) {
        col.dataType = "datetime";
        col.format = compt.format || "yyyy-MM-dd HH:mm:ss";
      } else if (["time"].includes(compt.type)) {
        col.dataType = "datetime";
        col.format = compt.format || "HH:mm:ss";
      }
      if (["companySelect", "company"].includes(compt.type)) {
        col.dataType = "company";
      } else if (["departmentSelect", "department"].includes(compt.type)) {
        col.dataType = "department";
      } else if (
        ["userSelect", "createuser", "modifyuser"].includes(compt.type)
      ) {
        col.dataType = "user";
      } else if (["areaselect"].includes(compt.type)) {
        col.dataType = "areas";
      }

      return col;
    },

    setTreeColumns(pid, headerMap, colMap, columns, _headerMap) {
      const col = headerMap[pid];
      if (col) {
        const props = [];
        this.setLeafColumnsProps(pid, _headerMap, props);
        delete headerMap[pid];

        columns.push({
          _id: col.prop,
          _pid: colMap[col.prop] || "0",
          id: col.prop,
          label: col.label,
          align: col.align,
          props: props,
        });

        this.setTreeColumns(
          colMap[col.prop] || "0",
          headerMap,
          colMap,
          columns,
          _headerMap
        );
      }
    },
    setLeafColumnsProps(pid, headerMap, res) {
      const col = headerMap[pid];
      if (col) {
        const props = col.value.split(",");
        props.forEach((v) => {
          this.setLeafColumnsProps(v, headerMap, res);
        });
      } else {
        res.push(pid);
      }
    },

    handleAddRow(myTable) {
      let point = {};
      myTable.children.forEach((col) => {
        if (
          ![
            "guid",
            "createtime",
            "modifytime",
            "company",
            "department",
            "createuser",
            "modifyuser",
          ].includes(col.type)
        ) {
          point[col.prop] = col.default || undefined;
        } else {
          point[col.prop] = "";
        }

        if (!col.display) {
          switch (col.type) {
            case "":
              point[col.prop] = `learun_code_${col.code}|${this.$uuid()}`;
              break;
            case "guid":
              point[col.prop] = this.$uuid();
              break;
            case "company":
              if (this.loginInfo) {
                point[col.prop] = this.loginInfo.f_CompanyId;
              }
              break;
            case "department":
              if (this.loginInfo) {
                point[col.prop] = this.loginInfo.f_DepartmentId;
              }
              break;
            case "createuser":
              if (this.loginInfo) {
                point[col.prop] = this.loginInfo.f_UserId;
              }
              break;
            case "modifyuser":
              if (this.loginInfo) {
                point[col.prop] = this.loginInfo.f_UserId;
              }
              break;
            case "createtime":
              point[col.prop] = this.$getDayTime();
              break;
            case "modifytime":
              point[col.prop] = this.$getDayTime();
              break;
          }
        }
      });
      this.formData[myTable.prop].push(point);
    },
    handleDeleteRow(event, myTable) {
      const row = this.formData[myTable.prop][event.index];
      this.formData[myTable.prop].splice(event.index, 1);
      row.learun_table_flag = "delete";
      this.handleItemChange(
        { prop: "table_row_delete" },
        myTable,
        event.index,
        row
      );
    },
    filterTableDeleteBtn(row) {
      if (row.hasNoDeleteBtn) {
        return false;
      } else {
        return true;
      }
    },

    merges(component) {
      const res = [];
      if (
        component.isRowFixed &&
        component.isRowMerge &&
        component.classType == "1" &&
        component.mergeRows
      ) {
        component.mergeRows.forEach((item) => {
          res.push([item.col, item.row1, item.row2]);
        });
      }
      return res;
    },

    getSlotName(prop, component) {
      if (
        ![
          "icon",
          "rate",
          "switch",
          "slider",
          "color",
          "file",
          "upload",
          "uploadimg",
        ].includes(component.type)
      ) {
        return prop;
      }
      return `${prop}${this.isRead ? "_read" : ""}`;
    },
  },
};
</script>
