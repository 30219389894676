<template>
  <div class="learun-not-auto-tab">
    <el-row>
      <el-collapse accordion v-model="tabActive">
        <el-collapse-item
          v-for="(item, index) in tabList"
          :key="index"
          :title="item.text"
          :name="index + ''"
        >
          <drag-form-wraper
            ref="dragFormWraper"
            :select.sync="selectWidget"
            :componentsList="collapseComponents(index)"
            :plist="parentComponents"
          />
        </el-collapse-item>
      </el-collapse>
    </el-row>
    <el-button
      :title="$t('复制')"
      @click.stop="handleWidgetClone(index)"
      class="drag-table-action-clone"
      v-if="selectWidget.prop == myComponent.prop"
      circle
      plain
      size="mini"
      type="primary"
    >
      <i class="el-icon-copy-document"></i>
    </el-button>
    <el-button
      :title="$t('删除')"
      @click.stop="handleWidgetDelete(index)"
      class="drag-table-action-delete"
      v-if="selectWidget.prop == myComponent.prop"
      circle
      plain
      size="mini"
      type="danger"
    >
      <i class="el-icon-delete"></i>
    </el-button>
  </div>
</template>
<script>
import mixin from "./mixin";
export default {
  name: "drag-form-collapse",
  mixins: [mixin()],
  data() {
    return {};
  },
  computed: {
    tabActive: {
      get() {
        return this.myComponent.active;
      },
      set(val) {
        this.myComponent.active = val;
      },
    },

    tabList() {
      return this.myComponent.tabList;
    },
    childrenComponents() {
      if (this.tabActive) {
        return this.tabList[parseInt(this.tabActive)].children;
      } else {
        return [];
      }
    },
  },
  methods: {
    collapseComponents(index) {
      return this.tabList[index].children;
    },
    handleWidgetClone(index) {
      let cloneData = this.$deepClone(this.myComponent);
      cloneData.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
      this.setAllProp(cloneData.tabList, true);
      this.parentComponents.splice(index, 0, cloneData);
      this.$nextTick(() => {
        this.handleSelectWidget(index + 1);
        this.$emit("change");
      });
    },
    handleFormClickAdd(item) {
      if (this.tabActive) {
        this.childrenComponents.push(item)
        this.$refs.dragFormWraper[
          parseInt(this.tabActive)
        ].handleFormAdd({ newIndex: this.childrenComponents.length - 1 }, true)
      }
      else{
        return false
      }
    },
  },
};
</script>
