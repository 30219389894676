<template>
  <div>
    <!--<el-form-item :label="$t('默认值')">
       <el-input v-model="data.default"
                :placeholder="$t('请输入')"></el-input>
    </el-form-item>-->
    <el-form-item :label="$t('高度')">
      <el-input-number v-model="data.height"
        controls-position="right"
        :placeholder="$t('请输入')"></el-input-number>
    </el-form-item>
    <el-form-item :label="$t('是否必填')">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-texteditor",
  props: ['data'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

