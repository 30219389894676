<template>
  <div>
    <el-divider>{{$t('私有属性')}}</el-divider>
    <el-form-item :label="$t('默认值')">
        <el-input-number v-model="data.default"
                        controls-position="right"
                        :placeholder="$t('请输入')"></el-input-number>
    </el-form-item>
     <el-form-item :label="$t('最小值')">
        <el-input-number v-model="data.min"
                        controls-position="right"
                        :placeholder="$t('请输入')"></el-input-number>
    </el-form-item>
    <el-form-item :label="$t('最大值')">
        <el-input-number v-model="data.max"
                        controls-position="right"
                        :placeholder="$t('请输入')"></el-input-number>
    </el-form-item>
    <el-form-item :label="$t('精度')">
        <el-input-number v-model="data.precision"
                        controls-position="right"
                        :placeholder="$t('请输入')"></el-input-number>
    </el-form-item>
    <el-form-item :label="$t('步长')">
        <el-input-number v-model="data.step"
                        controls-position="right"
                        :placeholder="$t('请输入')"></el-input-number>
    </el-form-item>
    <el-form-item :label="$t('步长倍数')">
        <el-switch v-model="data.stepStrictly"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('按钮位置')">
        <el-radio-group v-model="data.controlsPosition">
            <el-radio-button label="default">{{$t('默认')}}</el-radio-button>
            <el-radio-button label="right">{{$t('右侧')}}</el-radio-button>
        </el-radio-group>
    </el-form-item>
    <el-form-item :label="$t('是否可见')">
        <el-switch v-model="data.display"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否只读')">
        <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
    <el-divider>{{$t('校验')}}</el-divider>
    <el-form-item :label="$t('是否必填')">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-number",
  props: ['data'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

