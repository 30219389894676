<template>
  <div>
    <el-form-item :label="$t('标题')">
        <el-input v-model="data.label"
                    :placeholder="$t('请输入标题')"></el-input>
    </el-form-item>
    <el-form-item
        :label="$t('字体大小')"
      >
        <el-input-number
          v-model="data.fontSize"
          controls-position="right"
          :placeholder="$t('请输入')"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="颜色值">
        <l-input-color style="width: 212px" v-model="data.color">
    </l-input-color>
      </el-form-item>
    <el-form-item :label="$t('对齐方式')">
        <el-radio-group v-model="data.contentPosition">
            <el-radio-button label="left">{{$t('左')}}</el-radio-button>
            <el-radio-button label="center">{{$t('中')}}</el-radio-button>
            <el-radio-button label="right">{{$t('右')}}</el-radio-button>
        </el-radio-group>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-lable",
  props: ['data'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

