<template>
  <div>
    <el-divider>{{$t('数据')}}</el-divider>
    <!--远端数据-->
        <el-form-item :label="$t('数据源')">
            <l-select  
                    v-model="data.dataCode"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="dataSource" 
                    labelKey="f_Name" 
                    valueKey="f_Code"

                    @change="handleDataSourceChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item :label="$t('关联字段')">
            <l-select
                v-model="data.paramFiled"
                :placeholder="$t('请选择')"
                size="mini"
                :options="componentParamOptions"
            >
            </l-select>
        </el-form-item>
    
    <el-divider>{{$t('数据列')}}</el-divider>
        <div class="reg-item" :key="index" v-for="(item,index) in data.columns" >
        <el-form-item :label="$t('列名')" style="margin-bottom:8px;">
            <el-input v-model="item.label"  
                    :placeholder="$t('请输入')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('字段')" style="margin-bottom:8px;" >
            <l-select v-model="item.prop"  :options="myColNameList" ></l-select>
        </el-form-item>
        <el-form-item :label="$t('宽度')" style="margin-bottom:8px;" >
            <el-input v-model="item.width"
                    :placeholder="$t('请输入')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('对齐')" style="margin-bottom:0;" >
            <l-select v-model="item.align" :options="alignOptions" ></l-select>
        </el-form-item>

        <el-button :title="$t('删除')"
                    @click="handleRemoveCol(index)"
                    class="reg-item-delete"
                    circle
                    plain
                    size="mini"
                    type="danger">
            <i class="el-icon-close"></i>
        </el-button>
    </div>
    <div class="mt-8" >
        <el-button class="ml-8" type="primary" size="mini" @click="handleAddCol"  >{{$t('添加列')}}</el-button>
    </div>
    
  </div>
</template>

<script>
import mixin from '../../../mixin'
export default {
    name: "config-viewtable",
    mixins: [mixin()],
    inject: ["formDesign"],
    props: ['data'],
    data () {
        return {
            alignOptions:[{value:'left',label:'靠左'},{value:'center',label:'居中'},{value:'right',label:'靠右'}]
        }
    },
    created(){
        this.lr_loadDataSourceList && this.lr_loadDataSourceList()
        this.loadDataSourceCol()
    },
    computed:{
        dataSource(){
            if(this.lr_dataSource){
                return this.lr_dataSource
            }
            else{
                return []
            }
        },
        componentParamOptions() {
            const res = []
            if (this.formDesign) {
                const formInfo = this.formDesign.toSaveData()
                formInfo.tabList.forEach((tab) => {
                    tab.components.forEach((com) => {
                        if (!['gridtable','divider','viewtable','card','btn'].includes(com.type) && com.prop != this.data.prop) {
                            res.push({ value: com.prop, label: com.label })
                        }
                    })
                })
            }
            return res
        }
    },
    methods: {
        handleDataSourceChange(){
            if(!this.$validatenull(this.data.dataCode) && this.lr_loadDataSourceColNames){
                this.lr_loadDataSourceColNames(this.data.dataCode)
                this.lr_loadDataSourceData(this.data.dataCode)
            }
        },
        handleAddCol(){
            this.data.columns.push({
                label:`第${this.data.columns.length}列`,
                prop:'',
                width:100,
                align:'left',
                hidden:false
            })
        },
        handleRemoveCol(index){
            this.data.columns.splice(index,1)
        }
    }
}
</script>

