<template>
  <div>
    <el-form-item :label="$t('默认值')">
        <l-tree-select 
                :key="data.key"
                v-model="data.default"
                :placeholder="$t('请选择')" 
                size="mini"
                :options="myOptions"
            >
        </l-tree-select>
    </el-form-item>
    <el-divider>{{$t('选项')}}</el-divider>
    <div style="text-align: center;margin-bottom:16px;" >
        <el-radio-group v-model="data.dataType" size="mini" @change="handleDataTypeChange" >
            <el-radio-button label="1">{{$t('静态数据')}}</el-radio-button>
            <el-radio-button label="2">{{$t('数据字典')}}</el-radio-button>
            <el-radio-button label="3">{{$t('数据源')}}</el-radio-button>
        </el-radio-group>
    </div>
    <!--静态数据-->
    <div v-if="data.dataType == 1" >
        <el-tree ref="tree"
                   :data="data.options"
                   default-expand-all
                   draggable
                   node-key="value"
                   :expand-on-click-node="false">
            <span class="custom-tree-node"
                  slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                    <el-button type="text"
                            size="mini"
                            icon="el-icon-plus"
                            @click="handleNodeAdd(data)"></el-button>
                <!--                <el-button class="warning" type="text" size="mini" icon="el-icon-edit"-->
                <!--                           @click="handleNodeEdit(data)"></el-button>-->
                    <el-button class="danger"
                            type="text"
                            size="mini"
                            icon="el-icon-delete"
                            @click="handleNodeRemove(node, data)"></el-button>
                </span>
            </span>
        </el-tree>
        <div style="margin-left: 22px;">
            <el-button size="mini" type="text" icon="el-icon-circle-plus-outline" @click="handleParentNodeAdd"  >{{$t('添加父级')}}</el-button>
        </div>
    </div>
    <!--数据字典-->
    <div v-else-if="data.dataType == 2" >
        <l-tree-select
            v-model="data.dataCode"
            :options="dataItemClassifysTree"
            :placeholder="$t('请选择')"
            size="mini"
            :parent="false"

            @change="handleDataItemChange"
        >
        </l-tree-select>
    </div>
    <!--远端数据-->
    <div v-else >
        <el-form-item :label="$t('数据源')">
            <l-select  
                    v-model="data.dataCode"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="dataSource" 
                    labelKey="f_Name" 
                    valueKey="f_Code"

                    @change="handleDataSourceChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item :label="$t('值字段')">
            <l-select  
                    v-model="data.dataValueKey"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="myColNameList" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item :label="$t('名字段')">
            <l-select  
                    v-model="data.dataLabelKey"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="myColNameList" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item :label="`id${$t('字段')}`">
            <l-select  
                    v-model="data.dataIdKey"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="myColNameList" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
        <el-form-item :label="`pid${$t('字段')}`">
            <l-select  
                    v-model="data.dataPIdKey"
                    :placeholder="$t('请选择')" 
                    size="mini" 
                    :options="myColNameList" 

                    @change="handleDataSourceKeyLabelChange"
                >
            </l-select>
        </el-form-item>
        
        <el-form-item :label="$t('上一级')">
            <l-select
                v-model="data.upCtrl"
                :placeholder="$t('请选择')" 
                size="mini"
                :options="componentOptions"
            >
            </l-select>
        </el-form-item>
    </div>
    
    <el-divider></el-divider>
    <el-form-item :label="$t('是否只读')">
        <el-switch v-model="data.disabled"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否必填')">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>


    <l-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :height="200"

        @close="closeDialog"
        @ok="handleDialogAdd"
        >
        <div class="l-from-body" > 
            <el-form :model="dialogForm" size="mini" :rules="dialogRules"  ref="dialogForm" label-width="80px"  >
                <el-form-item :label="$t('选项名')" prop="label">
                    <el-input v-model="dialogForm.label"></el-input>
                </el-form-item>
                <el-form-item :label="$t('选项值')" prop="value">
                    <el-input v-model="dialogForm.value"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </l-dialog>
  </div>
</template>

<script>
import mixin from '../../../mixin'
export default {
    name: "config-treeselect",
    mixins: [mixin()],
    inject: ["formDesign"],
    props: ['data'],
    data () {
        return {
            dialogTitle:this.$t('添加选项'),
            dialogVisible:false,
            dialogForm: {},
            dialogRules: {
                label: { required: true, message: this.$t('请输入') ,trigger:'null' },
                value: { required: true, message: this.$t('请输入') ,trigger:'null'},
            },
            pData:undefined,
        }
    },
    created(){
        this.lr_loadDataItemClassifys && this.lr_loadDataItemClassifys()
        this.lr_loadDataSourceList && this.lr_loadDataSourceList()
        this.loadDataSourceCol()
    },
    computed:{
        dataItemClassifysTree(){
            if(this.lr_dataItemClassifysTree){
                return this.lr_dataItemClassifysTree
            }
            else{
                return []
            }
        },
        dataSource(){
            if(this.lr_dataSource){
                return this.lr_dataSource
            }
            else{
                return []
            }
        }
    },
    methods: {
        handleDataTypeChange(){
            this.data.default = '';
            this.data.dataIdKey = '';
            this.data.dataPIdKey = '';

            this.data.dataCode = '';
            this.data.dataValueKey = '';
            this.data.dataLabelKey = '';
        },
        handleParentNodeAdd(){
            this.pData = undefined;
            this.dialogTitle = this.$t('添加父级选项')
            this.dialogVisible = true;
        },
        handleNodeAdd(data){
            this.pData = data;
            this.dialogTitle = `${this.$t('添加')}【${data.label}】${this.$t('子选项')}`
            this.dialogVisible = true;
        },
        handleNodeRemove(node, data){
            this.data.default = ''
            const parent = node.parent;
            const children = parent.data.children || parent.data
            const index = children.findIndex(d => d.id === data.id)
            children.splice(index, 1)
            
        },

        handleDialogAdd () {
            this.$refs.dialogForm.validate((valid) => {
                if (valid) {
                    const { label, value } = this.dialogForm;
                    const node = this.$refs.tree.getNode(value)
                    if (node) this.$message.error(this.$t("选项值重复"))
                    else {
                        const pData = this.pData
                        const newNode = {
                            label,
                            value: this.dialogInputType == 'number' ? Number(value) : value,
                        }
                        if (pData) {
                            if (!pData.children) this.$set(pData, 'children', [])
                                pData.children.push(newNode)
                        } else {
                            this.$set(this.data.options, this.data.options.length, newNode)
                        }
                        this.dialogVisible = false
                    }
                }
            })
        },
        closeDialog () {
            this.$refs.dialogForm.clearValidate()
            this.dialogForm = {}
        },

        

        handleDataItemChange(){
            this.data.default = ''
            if(!this.$validatenull(this.data.dataCode) && this.lr_loadDataItem){
                this.lr_loadDataItem(this.data.dataCode)
            }
        },
        handleDataSourceChange(){
            this.data.default = ''
            this.data.dataValueKey = ''
            this.data.dataLabelKey = ''
            this.data.dataIdKey = ''
            this.data.dataPIdKey = ''
            if(!this.$validatenull(this.data.dataCode) && this.lr_loadDataSourceColNames){
                this.lr_loadDataSourceData(this.data.dataCode)
                this.lr_loadDataSourceColNames(this.data.dataCode)
            }
        },
        handleDataSourceKeyLabelChange(){
            this.data.default = ''
        }
    }
}
</script>