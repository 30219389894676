<template>
  <div>
    <el-form-item :label="$t('登录公司')">
        <el-switch v-model="data.isLogin" ></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否必填')">
        <el-switch v-model="data.required" ></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "config-companySelect",
  props: ['data'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

