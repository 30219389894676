<template>
  <div>
    <el-divider>{{ $t("私有属性") }}</el-divider>
    <el-form-item :label="$t('默认值')">
      <el-input v-model="config.default" :placeholder="$t('请输入')"></el-input>
    </el-form-item>
    <el-form-item :label="$t('前缀')">
      <el-input v-model="config.prepend" :placeholder="$t('前缀')"></el-input>
    </el-form-item>
    <el-form-item :label="$t('后缀')">
      <el-input v-model="config.append" :placeholder="$t('后缀')"></el-input>
    </el-form-item>
    <el-form-item :label="$t('前图标')">
      <l-input-icon onlyFirst v-model="config.prefixIcon"> </l-input-icon>
    </el-form-item>
    <el-form-item :label="$t('后图标')">
      <l-input-icon onlyFirst v-model="config.suffixIcon"> </l-input-icon>
    </el-form-item>

    <el-form-item :label="$t('最大长度')">
      <el-input-number
        v-model="config.maxlength"
        controls-position="right"
        :placeholder="$t('请输入')"
      ></el-input-number>
    </el-form-item>
    <el-form-item :label="$t('是否可见')">
      <el-switch :disabled="config.isNotSave" v-model="config.display"></el-switch>
    </el-form-item>
    <el-form-item :label="$t('是否只读')">
      <el-switch
        :disabled="config.isNotSave"
        v-model="config.readonly"
      ></el-switch>
    </el-form-item>
    <el-divider>{{ $t("校验") }}</el-divider>
    <el-form-item :label="$t('是否必填')">
      <el-switch v-model="config.required"></el-switch>
    </el-form-item>
    <div class="reg-item" :key="index" v-for="(item, index) in config.patterns">
      <el-form-item :label="$t('表达式')">
        <el-input v-model="item.reg" :placeholder="$t('请输入')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('错误提示')" style="margin-bottom: 0">
        <el-input v-model="item.msg" :placeholder="$t('请输入')"></el-input>
      </el-form-item>
      <el-button
        :title="$t('删除')"
        @click="handleRemovePatterns(index)"
        class="reg-item-delete"
        circle
        plain
        size="mini"
        type="danger"
      >
        <i class="el-icon-close"></i>
      </el-button>
    </div>
    <div class="mt-8">
      <el-dropdown @command="handleValidatorSelect">
        <el-button type="primary" size="mini">
          {{ $t("添加常用校验")
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :key="item.name"
            :command="item"
            v-for="item in validatorRules"
            >{{ item.name }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-button
        class="ml-8"
        type="primary"
        size="mini"
        @click="handleCustmerReg"
        >{{ $t("自定义规则") }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "config-input",
  props: ["data"],
  data() {
    return {};
  },
  computed: {
    config() {
      return this.data;
    },
    validatorRules() {
      if (this.lr_validatorRules) {
        return this.lr_validatorRules;
      } else {
        return [];
      }
    },
  },
  methods: {
    handleValidatorSelect(val) {
      this.config.patterns.push({
        msg: `${this.$t("请输入")}${val.name}`,
        reg: val.reg,
      });
    },
    handleCustmerReg() {
      this.config.patterns.push({
        msg: "",
        reg: "",
      });
    },
    handleRemovePatterns(index) {
      this.config.patterns.splice(index, 1);
    },
  },
};
</script>

