<template>
    <div>
        <el-divider>{{$t('私有属性')}}</el-divider>
        <el-form-item :label="$t('默认值')">
            <l-date
                clearable
                v-model="data.default"
                :dateType="data.dateType"
                :format="data.format"
                :startPlaceholder="data.startPlaceholder"
                :endPlaceholder="data.endPlaceholder"
                :placeholder="$t('请选择')" >
            </l-date>
        </el-form-item>
        <el-form-item :label="$t('时间类型')">
            <l-select v-model="data.dateType" :options="dateTypeOpions" @change="handleChange" ></l-select>
        </el-form-item>
        <el-form-item :label="$t('时间格式')">
            <el-input v-model="data.format" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('是否清空')">
            <el-switch v-model="data.clearable"></el-switch>
        </el-form-item>
        <el-form-item :label="$t('是否只读')">
            <el-switch v-model="data.readonly"></el-switch>
        </el-form-item>
        <el-divider>{{$t('校验')}}</el-divider>
        <el-form-item :label="$t('是否必填')">
            <el-switch v-model="data.required" ></el-switch>
        </el-form-item>
    </div>
</template>

<script>
export default {
  name: "config-datetimerange",
  props: ['data'],
  data () {
    return {
        dateTypeOpions:[
            {value:'daterange',label:this.$t('日期范围')},
            {value:'monthrange',label:this.$t('月范围')},
            {value:'datetimerange',label:this.$t('日期时间范围')},
        ]
    }
  },
  methods: {
    handleChange({value}){
      switch(value){
        case 'daterange':
          this.data.format = 'yyyy-MM-dd';
          break;
        case 'monthrange':
          this.data.format = 'yyyy-MM';
          break;
        case 'datetimerange':
          this.data.format = 'yyyy-MM-dd HH:mm:ss';
          break;
      }
    }
  }
}
</script>