<template>
  <div>
    <drag-form-wraper
      ref="dragFormWraper"
      :select.sync="selectWidget"
      :componentsList="childrenComponents"
      :plist="parentComponents"
    />
    <el-button
      :title="$t('复制')"
      @click.stop="handleWidgetClone(index)"
      class="drag-table-action-clone"
      v-if="selectWidget.prop == myComponent.prop"
      circle
      plain
      size="mini"
      type="primary"
    >
      <i class="el-icon-copy-document"></i>
    </el-button>
    <el-button
      :title="$t('删除')"
      @click.stop="handleWidgetDelete(index)"
      class="drag-table-action-delete"
      v-if="selectWidget.prop == myComponent.prop"
      circle
      plain
      size="mini"
      type="danger"
    >
      <i class="el-icon-delete"></i>
    </el-button>
  </div>
</template>
<script>
import mixin from "./mixin";
export default {
  name: "drag-form-card",
  mixins: [mixin()],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    childrenComponents() {
      return this.myComponent.children;
    },
  },
  methods: {
    handleWidgetClone(index) {
      let cloneData = this.$deepClone(this.myComponent);
      cloneData.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
      cloneData.table = "";
      cloneData.field = "";
      this.setAllProp(cloneData.children);
      this.parentComponents.splice(index, 0, cloneData);
      this.$nextTick(() => {
        this.handleSelectWidget(index + 1);
        this.$emit("change");
      });
    },
    handleFormClickAdd(item) {
      this.childrenComponents.push(item);
      this.$refs.dragFormWraper.handleFormAdd(
        { newIndex: this.childrenComponents.length - 1 },
        true
      );
    },
  },
};
</script>
