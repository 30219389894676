export default function () {
  return {
    props: ["myComponent", "select", "index", "plist"],
    inject: ["formDesign"],
    computed: {
      selectWidget: {
        get() {
          return this.select;
        },
        set(val) {
          this.$emit("update:select", val);
        },
      },
      parentComponents() {
        return this.plist || [];
      },
      dbTables() {
        return this.formDesign.dbTables;
      },
    },
    methods: {
      handleSelectWidget(index) {
        this.selectWidget = this.parentComponents[index];
      },
      handleWidgetDelete(index) {
        if (this.parentComponents.length - 1 === index) {
          if (index === 0) this.selectWidget = {};
          else this.handleSelectWidget(index - 1);
        } else this.handleSelectWidget(index + 1);
        this.$nextTick(() => {
          this.parentComponents.splice(index, 1);
          this.$emit("change");
        });
      },
      setAllProp(list, isTab) {
        if (isTab) {
          list.forEach((t) => {
            this.setAllProp(t.children)
          });
        } else {
          list.forEach((t) => {
            t.prop = Date.now() + "_" + Math.ceil(Math.random() * 99999);
            if (t.tabList) {
              this.setAllProp(t.tabList, true);
            } else if (t.children && t.children.length > 0) {
              this.setAllProp(t.children);
            }
          });
        }
      },
    }
  }
}